@import '~styles/variables';

.date-time-selector {
  align-items: center;
  background-color: $light-bg;
  border-radius: 10px;
  color: $tooltip-main-text;
  display: flex;
  font-weight: 500;
  height: 57px;
  justify-content: space-between;
  margin-right: 10px;
  padding: 15px 20px;
  width: 390px;

  .date-time {
    align-items: center;
    display: flex;

    .clock-icon {
      margin-right: 15px;
    }
  }

  .selectors {
    align-items: center;
    display: flex;

    .disabled {
      cursor: not-allowed;
    }

    .vertical-spacer {
      border-left: 1px solid $dark-grey;
      height: 28px;
      padding-right: 20px;
    }

    .arrow-selector {
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
  }
}

.calendar-popover {
  border: solid 1px $grey-uulight !important;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05) !important;
  margin: 9px;
  max-width: 100% !important;
  z-index: 10 !important;

  &.station-overlay {
    z-index: 100 !important;
  }
}

.period-selector {
  display: flex;
}

.confirm {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 30px;

  .date {
    color: $tooltip-main-text;
    display: flex;
    flex-grow: 1;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.2;
    padding-top: 21px;
    width: 100%;
  }

  .confirm-button {
    background: $green;
    color: $white;
    font-size: 1.6rem;
    font-weight: bold;
    height: 54px;
    line-height: 1.38;
    width: 327px;
  }
}
