@import '~styles/variables';

.calendar {
  background-color: $white;
  border-radius: 10px;
  bottom: 0;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  left: 275px;
  position: absolute;
}
