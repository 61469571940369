@import '~styles/variables';
@import '~styles/mixins';

.pole-prices-popover {
  @include popover-default-styles;

  width: 270px;

  .content {
    display: flex;
    flex-direction: column;
    min-height: 320px;
    padding: 10px 15px;

    .title {
      align-items: center;
      display: flex;
      font-size: 1.6rem;
      font-weight: 500;
      height: 35px;
      margin-bottom: 10px;
    }
  }
}
