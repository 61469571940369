@import '~styles/variables';
@import '~styles/mixins';

.lead-grade-indicator {
  align-items: center;
  border: solid 2px $white;
  border-radius: 50px;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: -10px;
  transform: translateX(-50%);
  width: 20px;
  z-index: 10;
}

.lead-grade-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 300px;
    text-align: center;
  }
}

