@import '~styles/variables';

.edge-intercom-launcher-container {
  bottom: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 14px;
  position: fixed;
}
