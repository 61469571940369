@import '~styles/mixins';

.payment-fallback-svg {
  height: 30px;
  width: 57px;
}

.payment-fallback-rect {
  fill: $payment-fallback-rect;
  height: 28px;
  stroke: $dark;
  stroke-width: 2;
  width: 55px;
}

.payment-fallback-text {
  dominant-baseline: middle;
  fill: $dark;
  font-size: 1.2rem;
  font-weight: bold;
  height: 28px;
  text-anchor: middle;
  width: 55px;
}
