@import '~styles/variables';

.overlay-header {
  display: flex;
  height: 100%;

  > div {
    align-items: center;
    border-bottom: $thin-grey-border;
    display: flex;
  }

  .station {
    border-right: $thin-grey-border;
    color: $dark;
    flex: 1;
    font-size: 2.4rem;

    > div:first-of-type {
      padding-left: 30px;
      padding-right: 15px;
    }
  }

  .station-selector {
    border-right: $thin-grey-border;
    color: $light;
    display: flex;
    padding: 0 40px;
    width: 320px;

    div {
      width: 160px;
    }

    svg {
      cursor: pointer;
      height: 40px;
      width: 40px;

      &.disabled {
        cursor: not-allowed;
      }
    }

    svg:first-of-type {
      margin-right: 10px;
    }
  }

  .exit {
    justify-content: center;
    width: 100px;

    svg {
      cursor: pointer;
      height: 40px;
      width: 40px;
    }
  }
}
