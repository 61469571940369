@import '~styles/variables';

.container {
  align-items: flex-start;
  background-color: #f6f8fa;
  border-radius: 10px;
  cursor: default;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 19px 20px 15px;
  position: relative;
  user-select: none;

  svg {
    flex: 0 0 auto;
    margin-right: 10px;
    margin-top: 1px;
  }

  + * {
    margin-top: 10px;
  }
}

.close {
  align-items: center;
  background: #fe4850;
  border: 0;
  border-radius: 10px;
  display: flex;
  flex-flow: row nowrap;
  height: 20px;
  justify-content: center;
  outline: none;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;

  svg {
    flex: 0 0 auto;
    margin: 0;
  }
}

.content {
  flex: 1 1 auto;
}

.title {
  color: #73818e;
  font-size: 1.6rem;
  font-weight: bold;
}

.timestamp {
  color: #b3bfc9;
  font-size: 1.2rem;
}

.body {
  color: #b3bfc9;
  font-size: 1.6rem;
  margin-top: 10px;

  a {
    color: #73818e;
    text-decoration: underline;
  }
}

.container.group-head {
  cursor: pointer;
}

.container.group-head,
.container.group-child {
  background-color: #e2e8ec;

  &.collapsed .chevron {
    transform: rotate(180deg);
  }

  .chevron {
    align-self: center;
    margin-right: -3px;
    transition: transform 150ms ease-in-out;
  }

  .title {
    align-items: center;
    color: #383d41;
    display: flex;
    flex-flow: row wrap;
  }

  .timestamp {
    color: #72818f;
  }

  .body {
    color: #72818f;
  }
}

.group-count {
  background-color: #ff5676;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  height: 16px;
  line-height: 1.33;
  margin-left: 10px;
  padding: 0 3px;
}
