.station-container {
  display: flex;
  flex-direction: column;
}

.fallback {
  background-color: inherit;
  box-shadow: none;
  flex: 1;
  margin: 0;
  padding: 0;

  > div {
    margin: 0;
  }
}

