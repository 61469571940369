@import '~styles/variables';

.selected-group-by {
  background-color: $white;
  border-radius: 5px;
  color: $dark;
  cursor: default;
  padding: 1px 5px;
  position: relative;

  &:first-of-type {
    margin-left: 20px;
  }

  &:not(:last-of-type) {
    margin-right: 20px;
  }

  .remove-button {
    align-items: center;
    background-color: $light-red;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
  }
}
