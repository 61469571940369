@import '~styles/variables';

.toggle-container {
  align-items: center;
  display: flex;

  .checkbox {
    height: 0;
    visibility: hidden;
    width: 0;
  }

  .toggle {
    border: 2px solid $light-bg;
    border-radius: 8px;
    background-color: $light-bg;
    cursor: pointer;
    padding: 20px;
    display: flex;
    height: 57px;
    margin-right: 0px;
    margin-left: 8px;
    position: relative;
    width: 150px;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    color: $grey;

    .toggle-button {
      background: $green;
      border-radius: 6px;
      height: 48px;
      position: absolute;
      transition: 0.2s;
      width: 70px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-bg;

      &.map {
        box-shadow: -2px 0px 2px rgba($dark, 0.5);
        left: 73px;
        right: 0px;
      }

      &.list {
        box-shadow: 2px 0px 2px rgba($dark, 0.5);
        left: 2px;
        right: 0px;
      }
    }
  }
}
