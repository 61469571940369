@import '~styles/variables';
@import '~styles/mixins';

.tab-nav-metrics-container-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.tab-nav-metrics-container-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.tab-nav-metrics-data {
  display: flex;
  flex-direction: row;
}

.metric-icon {
  height: 40px;
  margin-right: 1rem;
  width: 40px;

  @media screen and (max-width: 1280px) {
    margin-right: 0.5rem;
  }

  &.negative {
    path {
      fill: $red;
    }

    circle {
      fill: $pink-light;
    }
  }

  &.neutral {
    path {
      fill: #738180;
    }

    circle {
      fill: #e3e6e8;
    }
  }
}

.tab-nav-metric-title {
  color: $light;
  font-size: 1.6rem;
  font-style: normal;
  line-height: 1;
  white-space: nowrap;
}

.tab-nav-metrics-value {
  color: $dark;
  font-size: 2.4rem;
  height: 3.6rem;
  white-space: nowrap;
}

.tab-nav-metrics-trend {
  height: 2rem;
  line-height: 0.5;
  width: 4rem;
}

.disabled-mask {
  background-color: #fcfcfc;
  height: 100%;
  margin-top: -100%;
  opacity: 0.5;
  width: 100%;
}

.disabled-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 330px;
  }
}
