@import '~styles/variables';

.single-price-selector {
  align-items: center;
  display: flex;

  > svg {
    cursor: pointer;
  }

  > span {
    color: $dark;
    flex: 1;
    padding-left: 10px;
  }
}
