@import '~styles/variables';

.margin-info {
  align-items: center;
  color: $light;
  display: flex;
  flex-direction: row;
  font-size: 2.4rem;
  justify-content: flex-start;

  .value {
    color: $dark;
    line-height: 1.2;
  }

  .todays-margin {
    display: flex;
    flex-direction: column;
    margin-right: 30px;

    .title {
      font-size: 1.6rem;
    }
  }

  .estimated-margin {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      flex-direction: row;
      font-size: 1.6rem;
    }

    .info-tooltip {
      line-height: 1.2;
      padding-left: 10px;
    }
  }
}
