@import '~styles/mixins';

.buysmart-container {
  @include widget-default-styles;
  flex-direction: column;
  padding: 40px;
}

.buysmart-title {
  color: $dark;
  font-size: 2.4rem;
  margin-bottom: 40px;
}

.buysmart-widget-spinner {
  @include widget-default-styles;
  min-height: 682px;
  justify-content: flex-start;
  padding: 40px 0 40px 40px;
}

.tanks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .spacer {
    min-width: 40px;
  }

  .slider {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
  }

  .arrows {
    align-items: center;
    display: flex;
    min-width: 40px;
  }
}
