@import '~styles/variables';

.station-overlay {
  background-color: $light-bg;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: $sidebar-width;
  position: fixed;
  top: 0;
  width: calc(100% - #{$sidebar-width});
  z-index: 100;

  .header {
    background-color: $white;
    height: 80px;
  }

  .body {
    flex: 1;
    overflow-y: scroll;
    padding: 0 5%;

    @media screen and (max-width: $main-content-full-width) {
      padding: 50px 1%;
    }
  }
}
