@import '~styles/variables';
@import '~styles/mixins';

.prices-cell {
  @include competitor-pricing-cell;

  background-color: $competitor-pricing-competitor-station;
  padding-top: 20px;
  justify-content: center;

  &.influencer {
    background-color: $competitor-pricing-favourite;
  }

  &.popover {
    cursor: default;
  }

  .cash-price,
  .credit-price {
    color: $dark;
    font-size: 2.4rem;
    line-height: 1;
    padding-right: 20px;
    width: 50%;

    &.credit-price {
      padding-left: 20px;
    }
  }

  .price {
    padding-left: 3px;
  }

  .divider {
    @include competitor-pricing-price-divider;
  }
}
