@import '~styles/variables';

.submit-icon {
  cursor: pointer;
  margin-right: 26px;
}

.sending-text {
  cursor: pointer;
  margin-right: 20px;
}

.sent-text {
  color: $green;
  margin-right: 20px;
  z-index: 15;
}

.sent-icon {
  cursor: pointer;
  margin-right: 26px;
}

.error-text {
  color: $red;
  margin-right: 20px;
  z-index: 15;
}

.sending-icon-wrapper {
  cursor: pointer;
  margin-right: 22px;
  position: relative;
  z-index: 15;

  &.loading {
    cursor: not-allowed;
  }

  .cancel-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
