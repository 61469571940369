@import '~styles/variables';
@import '~styles/mixins';

.tooltip {
  @include tooltip-default-styles;

  cursor: default;
  font-size: 1.6rem;
}

.tooltip-content {
  text-align: center;
}
