@import '~styles/variables';

.action-at-popover {
  bottom: 10px !important;
  height: 235px;
  padding: 20px 15px;
  width: 270px;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;

    h3 {
      color: #72818f;
    }

    .confirm {
      color: $green;
      font-size: 1.6rem;
      font-weight: bold;
      padding: 10px 20px;
      text-align: right;
      text-decoration: underline;

      &.invalid > span {
        cursor: not-allowed;
      }

      > span {
        cursor: pointer;
      }
    }
  }
}
