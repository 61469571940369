@import '~styles/variables';

.fuel-grade-icon {
  align-items: center;
  border-radius: 7px;
  color: $white;
  display: flex;
  font-size: 1.6rem;
  font-weight: 500;
  height: 30px;
  justify-content: center;
  letter-spacing: -0.1rem;
  margin-right: 5px;
  width: 34px;
}
