@import '~styles/mixins';

.timeline-container {
  display: flex;
  margin: -20px 0 20px;
  padding: 40px;
}

.timeline {
  background-color: $light-bg;
  height: 2px;
  width: 100%;
}

.timeline-items {
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  margin-top: -48px;
}

.timeline-tooltip {
  @include tooltip-default-styles;
  bottom: -44px !important;
  flex-direction: column;
  text-align: center;
}

.timeline-tooltip-text {
  text-align: center;
}
