@import '~styles/variables';

.container {
  align-items: center;
  background-image: url('../../../../images/background.jpg');
  background-position: bottom center;
  background-size: cover;
  color: $white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  padding: 30px 0 30px 0;
}

.footer {
  background: $green;
  border-radius: 0 0 10px 10px;
  color: $white;
  font-weight: bold;
  margin-top: -10px;
  padding: 25px 15px 15px;
  position: relative;
  text-align: center;
  z-index: 0;
}

.error {
  background: $pink-med;
  border-radius: 0 0 10px 10px;
  color: $white;
  margin-top: -10px;
  padding: 25px 15px 15px;
  position: relative;
  text-align: center;
  z-index: 0;
}

.wrapper {
  border-radius: 10px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  width: 370px;
}

.login-form {
  align-items: stretch;
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  text-align: left;
  z-index: 1;

  button {
    background-color: $green;
    border-radius: 10px;
    border-style: none;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: bold;
    height: 54px;
    margin-bottom: 30px;
  }

  a {
    color: $light;
    font-size: 1.6rem;
    margin-bottom: 14px;
    margin-top: -10px;
    text-align: center;
  }
}

.header {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 50px;

  h1 {
    color: $dark;
    font-size: 2.4rem;
  }

  p {
    color: $light;
    font-size: 1.6rem;
    margin: 0 40px;
    text-align: center;
  }

  svg {
    margin-top: 20px;
  }

  svg + h1 {
    margin-top: 50px;
  }

  h1 + p {
    margin-top: 18px;
  }
}

.copyright {
  bottom: 0;
  left: 0;
  position: fixed !important;
  right: 0;
}
