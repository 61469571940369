@import '~styles/variables';

.background {
  align-items: center;
  background-attachment: fixed;
  background-color: #a5dcdf;
  background-image: url('../images/background.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100vw;

  @media screen and (max-width: 1350px) {
    background-image: url('../images/background-small.jpg');
  }

  .content {
    text-align: center;
    z-index: 10;

    h1 {
      font-size: 2.4rem;
    }

    h3 {
      color: $dark;
      font-size: 1.6rem;
    }

    button {
      height: 54px;
      margin-top: 45px;
      width: 140px;
    }
  }

  .background-text {
    color: rgba(0, 0, 0, 0.02);
    font-size: 30rem;
    font-weight: bold;
    left: 50%;
    letter-spacing: 0.5rem;
    padding-bottom: 80px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

    &.not-found {
      font-size: 50rem;
    }

    @media screen and (max-width: 1350px) {
      font-size: 20rem;

      &.not-found {
        font-size: 40rem;
      }
    }

    @media screen and (max-width: 830px) {
      font-size: 15rem;

      &.not-found {
        font-size: 30rem;
      }
    }
  }
}

.copyright {
  bottom: 0;
  left: 0;
  position: fixed !important;
  right: 0;
}
