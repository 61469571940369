@import '~styles/variables';
@import '~styles/mixins';

.fuel-grade-cell {
  @include competitor-pricing-cell;

  align-items: center;
  color: $white;
  justify-content: space-between;
  padding: 20px;
  position: relative;

  .lead-grade-icon {
    align-items: center;
    border: solid 2px $white;
    border-radius: 50px;
    display: flex;
    height: 20px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: -10px;
    transform: translateX(-50%);
    width: 20px;
    z-index: 10;
  }

  .text {
    display: flex;
    flex-direction: column;

    span {
      line-height: 1.2;
    }

    .title {
      font-size: 2.2rem;
    }
  }

  .differential-sort {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .fuel-grade-differential {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    &.lead span {
      cursor: default;
    }

    span {
      cursor: pointer;
    }
  }

  .fuel-grade-sort {
    align-items: center;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: center;
    margin-left: 9px;
    width: 31px;
  }
}
