@import '~styles/mixins';

.header-cell {
  display: flex;
  flex: 1;

  &:first-of-type {
    flex: 1.2;
    padding-left: 30px;
  }

  .metric-comparative-wrapper {
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    .metric {
      align-items: center;
      display: flex;
      font-weight: bold;

      &.sorted {
        color: $tooltip-comparative-text;
      }

      &.restricted {
        @include restricted;
      }
    }

    .comparative {
      color: #a2acb4;

      &.restricted {
        @include restricted;
      }
    }

    .sort-icon {
      margin-left: 5px;
    }
  }

  .percentage {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 0 0 35%;
    font-weight: bold;
    justify-content: center;

    &.no-sort {
      cursor: default;
    }

    &.restricted {
      @include restricted;
    }

    .sort-icon {
      margin-right: 5px;
    }
  }

  .sort-icon {
    &.hidden {
      visibility: hidden;
    }
  }
}
