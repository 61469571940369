@import '~styles/variables';
@import '~styles/mixins';

.pole-price-cell {
  align-items: flex-end;
  color: $dark;
  display: flex;
  flex: 1 0 140px;
  flex-direction: row;
  font-size: 2.4rem;
  padding-bottom: 13px;

  &.invalid {
    opacity: 0.4;

    &:hover .price-change-and-buttons .buttons {
      visibility: hidden;
    }
  }

  &:hover .price-change-and-buttons.show-buttons {
    .price-change {
      display: none;
    }

    .buttons {
      visibility: visible;
    }
  }

  .current-price {
    color: #b3bfc9;
    font-size: 1.6rem;
    line-height: 0.1;
  }

  .price-and-differential {
    display: flex;
    min-height: 50px;

    .price {
      align-items: flex-end;
      display: flex;
      width: 60px;
    }

    .differential {
      color: $dark;
      font-size: 1.4rem;
      line-height: 0.3;
    }

    .differential-and-buttons{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 7px;
      padding-bottom: 3px;
    }

    .price-change-and-buttons {
      display: flex;

      .buttons {
        visibility: hidden;

        > svg {
          cursor: pointer;
          margin-right: 4px;
        }
      }
    }
  }
}
