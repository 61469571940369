@import '~styles/variables';

.match-buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  svg {
    cursor: pointer;
  }

  .match {
    align-items: center;
    background-color: $dark-grey;
    border-radius: 4px;
    color: #708191;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    height: 16px;
    justify-content: center;
    margin: 0 3px;
    text-align: center;
    width: 40px;
  }
}
