@import '~styles/variables';
@import '~styles/mixins';

.info-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 350px;
  }
}
