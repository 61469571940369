@import '~styles/variables';
@import '~styles/mixins';

.current-price {
  color: $dark;
  font-size: 1.6rem;
  height: 16px;
  line-height: 1;
}

.cash-price,
.credit-price {
  align-items: center;
  display: flex;
  width: 50%;

  &.credit-price {
    padding-left: 10px;

    .buttons {
      padding-left: 5px;
    }
  }

  &.cash-price {
    .buttons {
      padding-right: 5px;
    }
  }

  .price-info {
    flex: 1;

    .price {
      color: $tooltip-comparative-text;
      font-size: 2.4rem;
      line-height: 1.2;
      line-height: 1.2;
      min-height: 2.4rem;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    svg {
      cursor: pointer;
      margin-top: 5px;
    }
  }
}

.divider {
  @include competitor-pricing-price-divider;
}
