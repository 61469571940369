@import '~styles/variables';

.group-row {
  background-color: #f4f6f7;
  border-bottom: 1px $grey-light solid;
  border-left: 2px solid #f4f6f7;
  cursor: pointer;
  display: flex;
  height: 81px;

  .group-cell {
    align-items: center;
    align-items: center;
    color: $dark;
    display: flex;
    flex: 1.2;
    margin-left: 30px;
    overflow: hidden;

    &.edit-pricing {
      margin-left: 0;
      margin-right: 30px;
    }

    img {
      margin-right: 15px;
    }

    > div:last-of-type {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden;
    }

    .group-name {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .station-count {
      padding-right: 10px;

      svg {
        transition: transform 150ms ease-in-out;
      }

      &.open svg {
        transform: rotate(180deg);
      }
    }

    @media screen and (max-width: $main-content-full-width) {
      img {
        height: 40px;
        width: 40px;
      }

      .station-count {
        font-size: 1.5rem;
      }
    }
  }
}

.loading {
  align-items: center;
  display: flex;
  height: 81px;
  justify-content: center;
}
