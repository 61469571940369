@import '~styles/mixins';

.competitor-pricing-container {
  @include widget-default-styles;

  flex-direction: column;
  min-height: 400px;
  padding: 40px 0 40px 40px;

  &.loading {
    align-items: center;
    justify-content: center;
  }

  .title {
    align-items: center;
    display: flex;
    padding-right: 40px;

    h2 {
      color: $dark;
      font-size: 2.4rem;

      span {
        color: $tooltip-comparative-text;
        font-weight: bold;
      }
    }
  }

  .options {
    align-items: center;
    display: flex;
    margin: 20px 0 40px;
    padding-right: 40px;

    &.confirm {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}
