@import '~styles/variables';

.wrapper {
  margin-top: 0px;
  width: 250px;
}

.fuel-grade-icon {
  margin-right: 15px;
}

.selected-item {
  display: flex;
  align-items: center;
}
