@import '~styles/mixins';

.wetstock-data-container {
  background-color: $light-bg;
  border-radius: $widget-border-radius;
  display: flex;
  flex-direction: column;
  height: 200px;
  margin-top: 10px;
  padding: 14px 20px;
}

.last-delivery-title {
  color: $dark;
  font-size: 2.4rem;
  padding-bottom: 20px;
}
