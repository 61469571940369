@import '~styles/variables';

.progress {
  height: 2px;
  position: absolute;
  width: 100%;

  .bar {
    animation: fill 5s linear 1;
    background-color: $green;
    height: 100%;
  }
}

@keyframes fill {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
