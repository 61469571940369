@import '~styles/mixins';

.target-margin-container {
  display: flex;
  flex-direction: column;
  max-width: 530px;
  width: 100%;
}

.target-margin {
  background-color: $light-bg;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 70px;
  justify-content: space-between;
  margin-bottom: 10px;
  max-width: 600px;
  min-width: 270px;
  padding: 14px 20px;
  width: 100%;
}

.target-margin-info {
  display: flex;
  flex-direction: column;
}

.target-margin-description {
  color: $light;
  font-size: 1.6rem;
  letter-spacing: normal;
  line-height: 1;
}

.target-margin-title {
  color: $dark;
  font-size: 2.4rem;
  margin-bottom: 40px;
}

.target-margin-value {
  color: $green;
  font-size: 2.4rem;
  line-height: 1.3;

  &.negative {
    color: $pink-med;
  }

  &.empty {
    color: $dark;
  }
}

.target-margin-edit {
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: row;
}
