@import '~styles/variables';
@import '~styles/mixins';

.container {
  @include widget-default-styles;
  flex-direction: column;
  padding: 0 0 40px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2.8rem;
  margin-right: 4rem;
  padding: 40px 40px 0;
}

.title {
  color: $dark;
  font-size: 2.4rem;
}

.cash-credit-pricing {
  color: #383d41;
  font-size: 2.4rem;
  font-weight: bold;
  margin-left: 0.25em;
}

.select-boxes {
  border-bottom: 1px solid $light-bg;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 40px;
  padding: 0 0 20px;

  > * {
    flex: 1 1 33.33%;
    max-width: 33.33%;

    + * {
      margin-left: 10px;
    }
  }
}

.stations-icon {
  margin-right: 17px;
  margin-top: -6px;
}

.performance-widget-spinner {
  display: flex;
  flex-direction: row;
  height: 659px;
  justify-content: space-evenly;
  padding: 40px;
}
