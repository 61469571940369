@import '~styles/variables';
@import '~styles/mixins';

.trend-indicator {
  align-items: center;
  color: $green;
  display: flex;
  flex-direction: row;
  height: 1.6rem;
  justify-content: center;
  line-height: 0.8;

  .trend-icon {
    height: 20px;
    width: 20px;

    &.neutral {
      width: 15px;
    }
  }

  .negative {
    color: $red;
  }

  .neutral {
    color: #72818f;
  }
}

.comparative-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 225px;

    .comparative-value {
      color: $tooltip-comparative-text;
      font-weight: 500;
    }
  }
}
