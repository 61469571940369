@import '~styles/variables';
@import '~styles/mixins';

.table-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  .table-body {
    display: flex;
    flex-direction: column;
  }
}

.loading {
  @include widget-default-styles;

  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 0;
  padding-top: 136px;
}
