@import '~styles/variables';

.header {
  box-shadow: $widget-box-shadow;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 100;
}
