@import '~styles/variables';
@import '~styles/mixins';

.container {
  @include widget-default-styles;
  flex-direction: column;
  padding: 0 0 40px;
}

.pro-widget-spinner {
  display: flex;
  flex-direction: row;
  height: 659px;
  justify-content: space-evenly;
  padding: 40px;
}

.export-buttons {
  display: flex;
  justify-content: flex-end;
}
