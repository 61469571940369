@import "~styles/variables";
@import '~styles/mixins';

.deliveries-container {
  margin-top: 10px;

  button {
    background-color: $green;
    border-radius: 10px;
    border-style: none;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: bold;
    height: 45px;
    margin-bottom: 30px;
  }
}

.deliveries-container button:disabled {
  background-color: $inactive !important;
  cursor: not-allowed;
}

.info-icon {
  margin-left: 5px;
  margin-top: -3px;
}

.info-icon-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 270px;
  }
}

.modal-overlay-style {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content-style {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  overflow-x: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 80%;
  height: 90%;
  max-height: 700px;
}

.modal-title {
  color: #73818e;
  text-align: center;
}

.wetstock-analysis-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wetstock-analysis-container {
  display: flex;
  justify-content: center;
}

.wetstock-analysis-column-container {
  margin-top: 12px;
  display: flex;
  border-radius: 6px;
}

.wetstock-analysis-column {
  text-align: center;
  padding: 10px;
  min-width: 160px;
  background-color: #f1f1f1;
}

.wetstock-analysis-title {
  color: #73818e;
  font-size: 15px;
}

.wetstock-analysis-metric {
  color: #656565;
  font-size: 20px;
}

.close-button-style {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
}

.tanks-containers {
  display: flex;
  justify-content: center;
  flex: 1;
}

.tanks-content {
  display: flex;
}

.tank-container {
  flex: 1;
  margin: 20px;
  transition: margin 0.3s ease;
  min-width: 100px;
  max-width: 150px;
}

.tank-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.tank-title {
  flex: 1;
  margin-bottom: 10px;
  color: #b3bfc9;
  text-align: center;
}

.tank-content {
  width: 100%;
  flex: 5;
  border: 2px solid;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  transition: width 0.3s ease;
}
