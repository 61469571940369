@import '~styles/variables';
@import '~styles/mixins';

.manual-input {
  input {
    background-color: #e0e3e6;
    border-radius: 5px;
    border-width: 0;
    bottom: 10px;
    cursor: pointer;
    height: 25px;
    left: 10px;
    margin-left: -5px;
    outline: none;
    padding: 0 2px;
    width: 65px;

    &.us-prices {
      width: 57px;
    }

    &::placeholder {
      color: rgba($black, 0.3);
    }

    &.enabled {
      background-color: $light-bg;
    }

    &.valid {
      border-color: #39c258;
      border-style: solid;
      border-width: 2px;
    }

    &.invalid {
      border-color: #ff5676;
      border-style: solid;
      border-width: 2px;
    }
  }
}

.manual-input-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 200px;
  }

  &.hide {
    display: none;
  }
}
