@import '~styles/variables';
@import '~styles/mixins';

.stations-list {
  @include widget-default-styles;
  background-color: transparent;
  box-shadow: none;
  flex: 1;
  margin-bottom: 0;
  min-width: 1080px;
  padding: 0;
}

.fallback {
  flex: 1;
  margin-bottom: 0;
  padding: 0;
}
