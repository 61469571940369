@import '~styles/variables';

.station-status {
  color: $dark;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div {
    margin-left: 30px;
  }
}
