@import '~styles/variables';

.stations-column {
  flex: 1;
  margin-right: 10px;

  > div:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .header-cell {
    align-items: center;
    background-color: $light-bg;
    border-radius: 10px;
    color: $dark;
    display: flex;
    flex: 380px 1 0;
    justify-content: space-between;
    font-size: 2.4rem;
    height: 70px;
    min-width: 420px;
    padding: 14px 20px;
  }

  .competitor-name-title {
    line-height: 0.8;
    margin-bottom: 4px;
  }

  .driving-distance {
    align-items: center;
    color: $light;
    display: flex;
    flex-direction: row;
    font-size: 1.6rem;
    margin-bottom: 6px;
  }

  .driving-distance-title {
    line-height: 1;
    margin-left: 10px;
  }

  .confirm-cell {
    height: 70px;
  }

  .driving-distance-sort {
    align-items: center;
    border-radius: 7px;
    border: solid 1px;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: space-evenly;
    width: 55px;
  }
}
