@import '~styles/variables';

.station-row {
  background-color: $white;
  border-bottom: 1px $grey-light solid;
  border-left: 2px solid $white;
  cursor: pointer;
  display: flex;
  height: 81px;

  &.invalid > div:not(:first-of-type) {
    opacity: 0.4;
  }

  &.no-cc {
    border-left: 2px solid $pricing-no-cash-credit;
  }

  .station-cell {
    align-items: center;
    display: flex;
    flex: 1.2;
    margin-left: 30px;
    overflow: hidden;

    img {
      margin-right: 15px;
    }

    &.edit-pricing {
      margin-left: 0;
      margin-right: 30px;
    }

    .station-info-wrapper {
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
      line-height: 1.4;
      overflow: hidden;

      .station-name {
        color: $dark;
        font-weight: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .target-margin {
        color: $green;

        &.negative {
          color: $pink-med;
        }

        &.restricted {
          height: 1.6rem;
        }
      }
    }

    @media screen and (max-width: $main-content-full-width) {
      img {
        height: 40px;
        width: 40px;
      }

      .station-name {
        font-size: 1.5rem;
      }
    }
  }
}
