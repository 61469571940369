@import '~styles/variables';

.fuel-grade-column {
  flex: 190px 0 0;
  margin-right: 10px;

  > div:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &:last-of-type {
    margin-right: 40px;
  }

  .confirm-cell {
    height: 70px;
  }
}
