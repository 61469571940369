@import '~styles/variables';

.background {
  align-items: center;
  background-color: $light-bg;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;

  .content {
    position: relative;

    .edge-logo {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.copyright {
  bottom: 0;
  left: 0;
  position: fixed !important;
  right: 0;
}
