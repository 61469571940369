@import '~styles/variables';

.table-body {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: $widget-box-shadow;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.editing-pricing {
    border-bottom-right-radius: 0;
    max-width: 60%;
  }

  &.loading {
    align-items: center;
    background-color: $white;
    flex: 1;
    justify-content: center;
  }
}

.loading-page {
  display: flex;
  justify-content: center;
  min-height: 100px;
  padding-top: 40px;
  width: 100%;
}
