@import '~styles/mixins';

.group-by {
  align-items: center;
  border-right: 1px #cfd6db solid;
  color: $dark;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: center;
  min-width: 150px;
  padding: 25px;

  &.restricted {
    cursor: not-allowed;
  }

  .group-by-text {
    margin-left: 15px;

    &.restricted {
      @include restricted;
    }
  }

  .group-by-icon {
    path {
      fill: $light;
    }
  }

  .selected-group-by {
    background-color: $white;
    border-radius: 5px;
    color: $dark;
    margin-left: 20px;
    padding: 1px 5px;
  }
}
