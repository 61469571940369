@import '~styles/variables';

.icon {
  margin-right: 17px;
  margin-top: -6px;
}

.popover {
  max-width: none !important;
  right: 10px !important;
  z-index: 10 !important;

  :global(.arrow) {
    display: none;
  }
}
