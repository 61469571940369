@import '~styles/variables';
@import '~styles/mixins';

.price-cell {
  @include competitor-pricing-cell;

  background-color: $competitor-pricing-competitor-station;
  flex-direction: column;
  justify-content: center;

  &.influencer {
    background-color: $competitor-pricing-favourite;
  }

  &.no-cash-credit {
    border: solid 1px $pricing-no-cash-credit;
  }

  &.popover {
    cursor: default;
  }

  .price-date {
    color: $light;
    line-height: 1.2;
  }

  .price-value {
    color: $dark;
    display: flex;
    font-size: 2.4rem;
    line-height: 1.2;

    div:last-of-type {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
}
