@import '~styles/variables';
@import '~styles/mixins';

.edit-pricing-selector {
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 15;

  &.pricing {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: 40px;
    z-index: 15;

    @media screen and (max-width: $main-content-full-width) {
      right: -10px;
    }
  }
}

.edit-pricing-tooltip {
  @include tooltip-default-styles;

  right: 10px !important;
}
