@import '~styles/mixins';

.wetstock-container {
  @include widget-default-styles;
  flex-direction: column;
  padding: 40px 0 40px 40px;
}

.wetstock-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2.8rem;
  margin-right: 4rem;
}

.wetstock-title {
  color: $dark;
  font-size: 2.4rem;
}

.wetstock-items-container {
  margin-right: 1rem;
}

.wetstock-slider {
  display: flex;
  flex-direction: row;
}

.wetstock-items {
  display: flex;
  flex-direction: row;
}

.slider-arrow-container {
  display: flex;
  flex-direction: row;
}

.slider-arrow {
  padding-left: 1rem;
}

.wetstock-spinner {
  @include widget-default-styles;
  height: 428px;
  justify-content: flex-start;
  padding: 40px 0 40px 40px;
}
