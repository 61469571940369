@import '~styles/variables';
@import '~styles/mixins';

.competitor-station-cell {
  @include competitor-pricing-cell;

  align-items: center;
  background-color: $competitor-pricing-competitor-station;
  color: $dark;
  cursor: pointer;
  flex: 380px 1 0;
  width: 100%;

  &.loading {
    cursor: default;
  }

  &.influencer {
    background-color: $competitor-pricing-favourite;
  }

  &.no-cash-credit {
    border: solid 1px $pricing-no-cash-credit;
  }

  &:hover .station-info .favourite-indicator {
    visibility: visible;
  }

  .station-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    line-height: 1.2;
    margin-left: 20px;

    .sort {
      align-items: center;
      color: $light;
      display: flex;

      svg {
        margin-right: 10px;
      }
    }

    .favourite-indicator {
      align-items: center;
      background-color: $competitor-pricing-favourite-icon;
      border-radius: 5px;
      color: $white;
      display: flex;
      font-size: 1.2rem;
      height: 18px;
      justify-content: center;
      margin-left: 8px;
      visibility: hidden;
      width: 70px;

      &.influencer {
        visibility: visible;
      }

      > .spinner {
        height: 10px;
      }
    }

    .station-name {
      font-size: 2.4rem;
    }
  }
}

.edge-station-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 145px;
  }
}

.no-cash-credit-tooltip {
  @include tooltip-default-styles;
}
