@import '~styles/variables';

.map-marker {
  display: flex;

  .main {
    display: flex;
    flex-wrap: nowrap;
    color: $white;
    border-radius: 5px;
    font-weight: 500;
    font-size: medium;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding-left: 5px;
    height: 32px;
    border: 1px solid $extra-dark;
    box-shadow: 3px 3px 2px rgba($dark, 0.5);
  }

  .default {
    background-color: $extra-dark;
  }

  .cheaper {
    background-color: $pink;
  }

  .match {
    background-color: $purple;
  }

  .expensive {
    background-color: $yellow;
    color: $extra-dark;
  }

  .no-price-data {
    background-color: $light;
  }

  .my-station-price {
    background-color: $white;
    color: $extra-dark;
  }

  .label {
    position: absolute;
    align-content: center;
  }

  .influencer {
    font-size: 12px;
    padding-left: 4px;
    top: -8px;
    right: 0px;
    margin-top: 0px;
    width: 12px;
    height: 12px;
  }

  .my-station {
    border-radius: 5px;
    font-size: small;
    margin-top: -45px;
    height: 20px;
    border: 1px solid $extra-dark;
    background-color: $green-med;
    width: 75px;
    text-align: center;
    color: $extra-dark;
    line-height: 10px;
  }

  .brand_img {
    background-color: $white;
    border-radius: 5px;
    height: 22px !important;
    width: 22px !important;
  }

  .selected-price {
    margin-left: 5px;
    width: 40.05px;
    text-align: center;

    .selected-all-price {
      display: inline;
      min-width: 40.05px;
      border-radius: 5px;
      float: inline-start;
    }
  }

  .selected-prices {
    margin-left: 5px;
    width: 85.05px;
    text-align: center;

    .selected-all-price {
      display: inline;
      min-width: 40.25px;
      border-radius: 5px;
      float: inline-start;
    }

    .selected-cash-price {
      display: inline;
      min-width: 40.25px;
      border-radius: 5px;
      float: inline-start;
    }

    .selected-credit-price {
      display: inline;
      min-width: 40.25px;
      border-radius: 5px;
      float: inline-end;
    }
  }

  .marker-tip {
    display: flex;
    justify-content: center;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    margin: auto;
    margin-top: -18px;
  }

  .default-tip {
    border-top: 14px solid $extra-dark;
  }

  .cheaper-tip {
    border-top: 16px solid $pink;
  }

  .match-tip {
    border-top: 16px solid $purple;
  }

  .expensive-tip {
    border-top: 16px solid $yellow;
  }

  .no-price-data-tip {
    border-top: 16px solid $light;
  }

  .marker-tip-border {
    display: flex;
    justify-content: center;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    margin: auto;
    border-top: 16px solid $extra-dark;
  }
}