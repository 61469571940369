@import '~styles/variables';

.header {
  align-items: center;
  border-bottom: 1px solid $grey-light;
  color: $tooltip-main-text;
  display: flex;
  font-weight: 500;
  min-height: 80px;
  padding: 0 30px;

  > span {
    align-items: center;
    border-right: 1px solid $grey-light;
    display: flex;
    flex: 1;
    height: 40px;
    margin-right: 30px;
  }

  > svg {
    cursor: pointer;
  }
}
