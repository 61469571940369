@import '~styles/variables';

.tab {
  align-items: center !important;
  background-color: $light-bg !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  color: $dark !important;
  display: flex !important;
  flex: 0 1 33% !important;
  flex-flow: row nowrap !important;
  height: 57px !important;
  justify-content: flex-start !important;
  padding: 18px 20px 19px !important;
  margin-top: 16px;
  font-weight: normal !important;
  white-space: nowrap;

  &.disabled {
    color: $light !important;
    cursor: not-allowed;

    svg path {
      fill: $light;
    }
  }

  input {
    display: none;
  }

  svg {
    margin-right: 15px;
  }

  svg path {
    fill: $dark;
  }

  + .tab {
    margin-left: 10px;
  }
}

.tab:global(.active) {
  border: 1px solid $green !important;
  color: $green !important;

  svg path {
    fill: $green;
  }
}

.type-options td:not(:last-child) {
  padding-right: 30px;
}

.checkbox,
.radio {
  background: none !important;
  border-style: none !important;
  box-shadow: none !important;
  color: $dark !important;
  font-size: 1.6rem;
  font-weight: 500;

  input {
    display: none;
  }

  svg {
    margin-right: 10px;
  }
}

.icon {
  margin-right: 17px;
  margin-top: -6px;
}

.select-boxes {
  border-bottom: 1px solid $light-bg;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 40px;
  padding: 0 0 5px;

  > * {
    flex: 1 1 16.7%;
    max-width: 16.7%;

    + * {
      margin-left: 10px;
    }
  }
}
