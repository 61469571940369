@import '~styles/variables';

.pending-change-request {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  > div:last-of-type {
    color: $orange;
    font-size: 2.4rem;
    line-height: 1.2;
  }
}
