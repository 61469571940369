@import '~styles/variables';

.backdrop {
  background-color: rgba($backdrop-background, 0.7);
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 1s, visibility 0s 0.8s;
  visibility: hidden;
  width: 100vw;

  &.visible {
    display: block;
    opacity: 1;
    transition: opacity 0.5s;
    visibility: visible;
  }
}
