@import '~styles/variables';
@import '~styles/mixins';

.status-icon {
  background-color: $pink-med;
  border-radius: 3px;
  height: 11px;
  margin-right: 5px;
  width: 11px;
}

.status-icon-tooltip {
  @include tooltip-default-styles;
}
