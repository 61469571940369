@import '~styles/variables';
@import '~styles/mixins';

.events-container {
  display: flex;
  flex-direction: row;
  min-height: 35px;
}

.multiple-events {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-right: -32px;
}

.multiple-with-warning {
  margin-left: 3px;
}

.tooltip-warning {
  @include tooltip-default-styles;

  > div:last-child {
    background-color: $buysmart-warning;
    color: $white;
    max-width: 225px;
  }

  div:first-child::before {
    border-top-color: $buysmart-warning;
  }
}

.tooltip-stockout {
  @include tooltip-default-styles;

  > div:last-child {
    background-color: $buysmart-stockout;
    color: $white;
    max-width: 225px;
  }

  div:first-child::before {
    border-top-color: $buysmart-stockout;
  }
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  justify-content: center;
}

.tooltip-svg {
  display: flex;
  fill: $white;
  justify-content: center;
}

.tooltip-text-header {
  align-content: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  text-align: center;
}

.tooltip-text-tanks {
  text-align: center;
}

.tooltip-text-tanks-info {
  font-weight: bold;
  text-align: center;
}

.tooltip-text-date {
  font-weight: bold;
  text-align: center;
}
