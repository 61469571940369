@import '~styles/variables';

.navbar {
  height: $header-height-component;
  background-color: $primary-bg;
  display: flex;
}

.logo-container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.logo {
  margin-bottom: 10px;
}

.navbar-line {
  width: 100%;
  border-bottom: $thin-grey-border;
}
