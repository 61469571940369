@import '~styles/variables';
@import '~styles/mixins';

$breakpoint-one: 1220px;

.company-metric {
  align-items: center;
  border-right: $thin-grey-border;
  display: flex;
  flex: 1;
  height: 100%;
  padding: 0 3rem;

  &.single {
    max-width: 50%;
  }

  @media screen and (max-width: $breakpoint-one) {
    padding: 0 1.5rem;
  }

  .metric-icon {
    height: 50px;
    margin-right: 2rem;
    width: 50px;

    @media screen and (max-width: $breakpoint-one) {
      height: 40px;
      margin-right: 1rem;
      width: 40px;
    }

    &.negative {
      path {
        fill: $red;
      }

      circle {
        fill: $pink-light;
      }
    }

    &.neutral {
      path {
        fill: #738180;
      }

      circle {
        fill: #e3e6e8;
      }
    }
  }

  .metric-data {
    display: flex;
    flex: 1;
    flex-direction: column;

    > div:first-of-type {
      color: $light;
      display: flex;
      font-size: 1.6rem;
      line-height: 1.2;

      @media screen and (max-width: $breakpoint-one) {
        font-size: 1.3rem;
        line-height: 1;
      }

      .name {
        margin-right: auto;
      }
    }

    > div:last-of-type {
      color: $dark;
      font-size: 2.4rem;
      height: 2.4rem;
      line-height: 1.2;

      @media screen and (max-width: $breakpoint-one) {
        font-size: 1.8rem;
        height: 1.8rem;
        line-height: 1;
      }
    }
  }

  .performance-trend {
    width: 40px;
  }
}

.metric-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 360px;
    text-align: center;
    white-space: pre-line;
  }
}
