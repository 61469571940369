@import '~styles/variables';

.timeline-item-container {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.timeline-date {
  color: $light;
  font-size: 1.6rem;
  margin-top: 3px;

  &.selected {
    color: $dark;
  }
}
