@import '~styles/variables';

.input {
  background-color: $light-bg;
  border: 1px solid transparent;
  border-radius: 10px;
  display: flex;
  flex-flow: row nowrap;
  height: 57px;

  overflow: hidden;

  position: relative;

  input {
    background: none;
    border-style: none;
    flex: 1 1 auto;
    outline: none;
    padding: 20px;
  }

  ::placeholder {
    color: $tooltip-main-text;
  }

  ~ .input,
  ~ button {
    margin-top: 10px;
  }

  &.focused {
    background: #fff;
    border: 1px solid $green;
  }
}

.invalid::after,
.valid::after {
  content: '\00a0';
  display: inline-block;
  height: 20px;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  z-index: 1;
}

.invalid::after {
  background: url('../../../images/forms/invalid.svg');
}

.valid::after {
  background: url('../../../images/forms/valid.svg');
}

.help-text {
  color: $light;
  font-size: 1.2rem;
  line-height: 150%;
  margin: 20px 0 0;
  text-align: center;
  font-weight: bold;
}
