@import '~styles/mixins';

.wetstock-metric-container {
  margin-bottom: 10px;
}

.wetstock-metric-title {
  color: $light;
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 2px;
}

.wetstock-metric-value {
  color: $dark;
  display: flex;
  flex-direction: column;
  font-size: 2.4rem;
  height: 2.4rem;
  line-height: 1;
  margin-bottom: 5px;
}

.info-icon-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 160px;
    padding: 1rem;
  }

  .days-ago {
    font-weight: bold;
  }
}


