@font-face {
  font-family: 'CircularXXSub-BookSubset';
  src: url('CircularXXSub-BookSubset.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-BookSubset';
  src: url('CircularXXSub-BookSubset.woff') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-MediumSubset';
  src: url('CircularXXSub-MediumSubset.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-MediumSubset';
  src: url('CircularXXSub-MediumSubset.woff') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-BoldSubset';
  src: url('CircularXXSub-BoldSubset.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-BoldSubset';
  src: url('CircularXXSub-BoldSubset.woff') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-RegularSubset';
  src: url('CircularXXSub-RegularSubset.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-RegularSubset';
  src: url('CircularXXSub-RegularSubset.woff') format('woff2');
}

/* Complete */

@font-face {
  font-family: 'CircularXXWeb-Book';
  src: url('CircularXXWeb-Book.woff') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Book';
  src: url('CircularXXWeb-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Medium';
  src: url('CircularXXWeb-Medium.woff') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Medium';
  src: url('CircularXXWeb-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Bold';
  src: url('CircularXXWeb-Bold.woff') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Bold';
  src: url('CircularXXWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Regular';
  src: url('CircularXXWeb-Regular.woff') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Regular';
  src: url('CircularXXWeb-Regular.woff2') format('woff2');
}
