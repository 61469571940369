@import '~styles/variables';

.map-legend {
  position: absolute;
  z-index: 400;
  bottom: 12px;
  left: 12px;
  background-color: $white;
  border-radius: 4px;
  min-width: 200px;
  font-size: small;
  box-shadow: 0px 0px 0px 2px rgba($dark, 0.4);
  cursor: pointer;

  .close-arrow {
    position: absolute;
    top: 2px;
    right: 2px;
    transform: scale(0.75);
  }

  .open-arrow {
    position: absolute;
    top: 2px;
    right: 2px;
    transform: scale(0.75);
    transform: rotateX(180deg);
  }

  .legend-row {
    display: flex;
    margin: 5px;
    margin-right: 15px;
    line-height: 24px;
    align-items: center;

    .legend-icon {
      display: flex;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      margin-right: 8px;
    }

    .cheaper {
      background-color: $pink;
    }

    .match {
      background-color: $purple;
    }

    .expensive {
      background-color: $yellow;
    }

    .no-price-data {
      background-color: $light;
    }
  }
}
