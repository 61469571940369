@import '~styles/variables';
@import '~styles/bootstrap';
@import '~styles/mixins';

.container {
  background: $primary-bg;
  padding: 0 40px;
  position: relative;
}

.graphic {
  line.sunday,
  line.saturday {
    stroke: rgba(221, 228, 234, 0.6);
  }

  rect.sunday,
  rect.saturday {
    fill: #fbfcfd;
    stroke: #fbfcfd;
  }

  path.line {
    fill: none;
    pointer-events: none;
    stroke: $green;
    stroke-width: 2px;

    // stylelint-disable-next-line selector-class-pattern
    &.totalVolume {
      stroke: $green;

      &.area {
        fill: $green-med;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    &.fuelCardVolume {
      stroke: $purple;

      &.area {
        fill: $purple-light;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMarginAll {
      stroke: $purple;

      &.area {
        fill: $purple-light;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMargin {
      stroke: $purple;

      &.area {
        fill: $purple-light;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    &.bunkeredVolume {
      stroke: $blue;

      &.area {
        fill: $blue-light;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfit {
      stroke: $purple;

      &.area {
        fill: $purple-light;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfit {
      stroke: $blue;

      &.area {
        fill: $blue-light;
      }
    }

    &.area {
      fill: $green-med;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfitAll {
      stroke: $purple;

      &.area {
        fill: $purple-light;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfitAll {
      stroke: $blue;

      &.area {
        fill: $blue-light;
      }
    }

    &.area {
      fill: $green-med;
    }
  }

  circle.line {
    fill: $green;
    pointer-events: none;
    stroke: none;

    // stylelint-disable-next-line selector-class-pattern
    &.netMarginAll {
      fill: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMargin {
      fill: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.fuelCardVolume {
      fill: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.bunkeredVolume {
      fill: $blue;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfit {
      fill: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfit {
      fill: $blue;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfitAll {
      fill: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfitAll {
      fill: $blue;
    }
  }

  path.previous-line {
    fill: none;
    pointer-events: none;
    stroke: $green-med;
    stroke-width: 2px;

    // stylelint-disable-next-line selector-class-pattern
    &.fuelCardVolume {
      stroke: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.bunkeredVolume {
      stroke: $blue-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMarginAll {
      stroke: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMargin {
      stroke: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfit {
      stroke: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfit {
      stroke: $blue-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfitAll {
      stroke: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfitAll {
      stroke: $blue-light;
    }
  }

  circle.previous-line {
    fill: $green-med;
    pointer-events: none;
    stroke: none;

    // stylelint-disable-next-line selector-class-pattern
    &.fuelCardVolume {
      fill: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.bunkeredVolume {
      fill: $blue-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMarginAll {
      fill: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMargin {
      fill: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfit {
      fill: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfit {
      fill: $blue-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfitAll {
      fill: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfitAll {
      fill: $blue-light;
    }
  }

  .y-tick {
    pointer-events: none;
    stroke: $grey-vlight;
    stroke-dasharray: 3 3;
  }

  .x-axis {
    pointer-events: none;
    stroke: $grey-vlight;
  }

  .x-axis-text {
    fill: $light;
    font-family: $font-family-sans-serif;
    font-size: 1.6rem;
    pointer-events: none;
    text-anchor: middle;

    &.x-axis-text-rotated {
      text-anchor: end;
      transform: translateX(-20px) rotate(-60deg);
    }
  }

  .y-axis-text {
    fill: $light;
    font-family: $font-family-sans-serif;
    font-size: 1.6rem;
    pointer-events: none;
  }

  .x-grid {
    pointer-events: all;
    stroke: $grey-vlight;
  }

  .y-axis-label {
    fill: $dark;
    font-family: $font-family-sans-serif;
    font-size: 1.6rem;
    pointer-events: none;
    text-anchor: middle;
  }
}

.tooltip {
  @include tooltip-default-styles;

  font-size: 1.6rem;
  pointer-events: none;
  z-index: 5 !important;

  > :global(.tooltip-inner) {
    max-width: none !important;
  }

  .metric-change-up {
    color: $green;
  }

  .metric-change-down {
    color: $red;
  }
}

.tooltip-date {
  color: $dark;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.38;
  margin-bottom: 12px;
  white-space: nowrap;
}

.tooltip-periods {
  th,
  td {
    border-left: 1px solid $grey-ulight;
    padding: 0 20px;

    &:first-child {
      border-left-style: none;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  th {
    color: $light;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1;
    padding-bottom: 8px;

    svg {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: -4px;
    }

    .metric-change-up {
      margin-left: -5px;
    }
  }

  td {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1;
  }

  td.previous-line {
    color: $green-med;

    // stylelint-disable-next-line selector-class-pattern
    &.fuelCardVolume {
      color: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.bunkeredVolume {
      color: $blue-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMarginAll {
      color: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMargin {
      color: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfit {
      color: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfit {
      color: $blue-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfitAll {
      color: $purple-light;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfitAll {
      color: $blue-light;
    }
  }

  td.line {
    color: $green;

    &.metric-change-down {
      color: $red !important;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.fuelCardVolume {
      color: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.bunkeredVolume {
      color: $blue;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMarginAll {
      color: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netMargin {
      color: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfit {
      color: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfit {
      color: $blue;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.netProfitAll {
      color: $purple;
    }

    // stylelint-disable-next-line selector-class-pattern
    &.businessProfitAll {
      color: $blue;
    }
  }
}

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 75%;
  padding: 40px 0 20px;

  .legend-line {
    align-items: center;
    color: $light;
    display: flex;
    flex-flow: row nowrap;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 0.75;
    margin-bottom: 20px;

    + .legend-line {
      margin-left: 30px;
    }

    &::before {
      border-radius: 8px;
      content: '\00a0';
      display: inline-block;
      height: 16px;
      margin-right: 10px;
      width: 16px;
    }
  }

  .legend-line:nth-child(1)::before {
    background: $green;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.fuelCardVolume::before {
    background: $purple;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.bunkeredVolume::before {
    background: $blue;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.netMarginAll::before {
    background: $purple;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.netMargin::before {
    background: $purple;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.netProfit::before {
    background: $purple;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.businessProfit::before {
    background: $blue;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.netProfitAll::before {
    background: $purple;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.businessProfitAll::before {
    background: $blue;
  }

  .legend-line.previous-line::before {
    background: $green-med;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.fuelCardVolume.previous-line::before {
    background: $purple-light;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.bunkeredVolume.previous-line::before {
    background: $blue-light;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.netMarginAll.previous-line::before {
    background: $purple-light;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.netMargin.previous-line::before {
    background: $purple-light;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.netProfit.previous-line::before {
    background: $purple-light;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.businessProfit.previous-line::before {
    background: $blue-light;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.netProfitAll.previous-line::before {
    background: $purple-light;
  }

  // stylelint-disable-next-line selector-class-pattern
  .legend-line.businessProfitAll.previous-line::before {
    background: $blue-light;
  }
}
