@import '~styles/variables';
@import '~styles/mixins';

.container {
  background: $primary-bg;
  padding: 0 80px 0 20px;
  position: relative;
}

.chart {
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 5px;
}

.grid {
  pointer-events: none;
  stroke: $grey-vlight;
}

.tooltip {
  padding: 10px;
  width: auto;
  height: 100px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba($black, 0.05);
}

.tooltip-date {
  color: $dark;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.38;
  margin-bottom: 12px;
  white-space: nowrap;
}

.tooltip-periods {
  th,
  td {
    border-left: 1px solid $grey-ulight;
    padding: 0 20px;

    &:first-child {
      border-left-style: none;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  th {
    color: $light;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1;
    padding-bottom: 8px;
    white-space: nowrap;

    svg {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: -4px;
    }
  }

  td {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1;
  }
}

.metric-change-up {
  color: $green;
}

.metric-change-down {
  color: $red;
  padding: 0 4px 0 4px;
}
