@import '~styles/mixins';

.last-delivery-container {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 380px;
}

.last-delivery {
  background-color: $light-bg;
  border-radius: $widget-border-radius;
  display: flex;
  flex-direction: row;
  height: 75px;
  padding: 11px;
  width: 380px;
}

.last-delivery-title {
  color: $dark;
  font-size: 2.4rem;
  padding-bottom: 30px;
}

.last-delivery-text {
  padding-left: 25px;

  .title {
    color: $light;
    font-size: 1.6rem;
    line-height: 1.1;
    margin-top: 3px;
  }

  .value {
    color: $dark;
    font-size: 2.4rem;
    margin-top: -2px;
  }
}
