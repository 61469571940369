@import '~styles/variables';

.dropdown {
  align-items: center;
  align-self: center;
  background-color: $light-bg;
  border: 1px solid $light-bg;
  border-radius: 10px;
  color: $dark;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: 57px;
  justify-content: space-between;
  margin-top: 10px;
  min-height: 57px;
  padding: 20px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-icon {
    margin-left: 20px;
    min-width: 20px;
  }

  &.open {
    border: 0;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.selected_dropdown {
    border: 1px solid $green;
  }

  .placeholder {
    color: $light;
  }
  .selected-dropdown {
    color: $green;

    svg path {
      fill: $green;
    }
  }

  .items {
    background-color: $white;
    border: 1px solid $green;
    border-radius: 10px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    .selected-item {
      align-items: center;
      border-bottom: 1px solid $grey-uulight;
      display: flex;
      height: 57px;
      justify-content: space-between;
      padding: 20px;
    
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

 
    }

    .items-list {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
}
