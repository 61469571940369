@import '~styles/mixins';

.nav-row {
  padding: 20px;
}

.nav-station-overview {
  background: $stations-search-background;
  border-radius: $widget-border-radius;
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: 10px;
  padding: 20px;
  z-index: 4;
}

.nav-tab {
  background: $stations-search-background;
  border-radius: $widget-border-radius;
  cursor: pointer;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: 10px;
  margin-left: 10px;
  min-height: 80px;
  padding: 20px 20px 15px;
  z-index: 0;
}

.nav-tab-first-tab {
  background: $stations-search-background;
  border-radius: $widget-border-radius;
  cursor: pointer;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 10px;
  margin-left: 0px;
  min-height: 80px;
  padding: 20px 20px 15px;
  z-index: 0;
}

.nav-metrics-inner {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
}

.nav-tab-active {
  background-color: $primary-bg;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 10px;
  min-height: 80px;
  padding: 20px 20px 15px;
  width: 100%;
  cursor: pointer;
}

.nav-tab-active-first-tab {
  background-color: $primary-bg;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 0px;
  min-height: 80px;
  padding: 20px 20px 15px;
  width: 100%;
  cursor: pointer;
}

// external curve on tabs
.external-corner-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.external-corner-left {
  height: 15px;
  margin-bottom: -10px;
  margin-left: -35px;
  width: 15px;
}

.svg-left {
  height: 100%;
  margin-bottom: -8px;
  vertical-align: unset;
  width: 100%;
}

.svg-rect-left {
  fill: $white;
  height: 100%;
  width: 100%;
  x: 0;
  y: 0;
}

.svg-circle-left {
  cx: 0;
  cy: 0;
  fill: $light-bg;
  r: 100%;
}

.external-corner-right {
  height: 15px;
  margin-bottom: -10px;
  margin-right: -35px;
  width: 15px;
}

.svg-right {
  height: 100%;
  margin-bottom: -8px;
  vertical-align: unset;
  width: 100%;
}

.svg-rect-right {
  fill: $white;
  height: 100%;
  width: 100%;
  x: 0;
  y: 0;
}

.svg-circle-right {
  cx: 100%;
  cy: 0;
  fill: $light-bg;
  r: 100%;
}

.nav-station-tab-name {
  color: $dark;
  font-size: 2.4rem;
  line-height: 1;
}
