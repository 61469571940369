@import '~styles/variables';

.container {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 0 80px;
  text-align: center;

  > span {
    align-items: center;
    background-color: $green;
    border-radius: 50px;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    height: 42px;
    justify-content: center;
    margin-bottom: 35px;
    width: 42px;

    svg {
      height: 21px;
      width: 16px;
    }

    svg path {
      fill: $white;
    }
  }

  > h2 {
    color: $dark;
    margin-bottom: 15px;
  }

  > p {
    color: $light;
  }
}
