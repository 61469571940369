.price-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .price-input-wrapper {
    h4 {
      font-size: 1.6rem;
    }

    .price-input {
      font-size: 1.6rem;
      margin-bottom: 10px;
      width: 110px;
    }
  }
}
