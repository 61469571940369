@import '~styles/mixins';

.payments-container {
  display: flex;
  flex-direction: column;
  max-width: 530px;
  width: 100%;
}

.payments-title {
  align-items: flex-start;
  color: $dark;
  display: flex;
  font-size: 2.4rem;
  height: 72px;
  line-height: 1.0;
  margin-top: 5px;
}

.payments-body {
  // max height set to 5 payment components high
  max-height: 390px;
  overflow-y: scroll;
  padding-right: 10px;
}

.payments-bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 54%,
    #fff 54%
  );
  height: 35px;
  margin-top: -35px;
  width: 98%;
  z-index: 2;
}

::-webkit-scrollbar-track {
  background-color: $track;
  border-radius: 2.5px;
}

::-webkit-scrollbar-thumb {
  background-color: $dark-grey;
  border-radius: 2.5px;
  height: 20px;
}

::-webkit-scrollbar {
  height: 20px;
  width: 6px;
}
