@import '~styles/variables';

.types-row {
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 40px;
  padding: 20px 0;
}

.tabs {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.tab {
  align-items: center !important;
  background-color: $light-bg !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  color: $dark !important;
  display: flex !important;
  flex: 0 1 33% !important;
  flex-flow: row nowrap !important;
  height: 57px !important;
  justify-content: flex-start !important;
  max-width: 188px !important;
  padding: 18px 20px 19px !important;

  &.disabled {
    color: $light !important;
    cursor: not-allowed;

    svg path {
      fill: $light;
    }
  }

  input {
    display: none;
  }

  svg {
    margin-right: 15px;
  }

  svg path {
    fill: $dark;
  }

  + .tab {
    margin-left: 10px;
  }
}

.tab:global(.active) {
  background-color: $green-med-light !important;
  border: 1px solid $green !important;
  color: $green !important;

  svg path {
    fill: $green;
  }
}

.type-options td:not(:last-child) {
  padding-right: 30px;
}

.checkbox,
.radio {
  background: none !important;
  border-style: none !important;
  box-shadow: none !important;
  color: $dark !important;
  font-size: 1.6rem;
  font-weight: 500;

  input {
    display: none;
  }

  svg {
    margin-right: 10px;
  }
}
