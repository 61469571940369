@import '~styles/variables';
@import '~styles/mixins';

.group-by-popover {
  @include popover-default-styles;

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    padding: 20px;
    width: 230px;

    .title {
      font-size: 1.6rem;
      font-weight: 500;
      padding-bottom: 10px;
    }

    .group-by {
      align-items: center;
      border-top: 1px solid $secondary-bg;
      color: $tooltip-main-text;
      cursor: pointer;
      display: flex;
      height: 50px;
      justify-content: space-between;
    }
  }
}
