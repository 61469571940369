@import '~styles/mixins';

.month-icon-container {
  align-items: center;
  background-color: $pink-med;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  padding-bottom: 3px;
  width: 50px;
}

.month-icon-month {
  color: #fff;
  font-size: 12px;
  font-weight: 800;
}

.month-icon-day {
  background-color: #fff;
  border-radius: 5px;
  color: $dark;
  font-size: 24px;
  font-weight: 600;
  height: 30px;
  line-height: 1.3;
  text-align: center;
  width: 46px;
}
