@import '~styles/mixins';

.price-change-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 236px;
    text-align: center;

    span {
      color: #f3613d;
    }
  }
}
