@import '~styles/variables';
@import '~styles/mixins';

.confirm-cell {
  @include competitor-pricing-cell;

  align-items: center;
  background-color: #fff2ef;
  border: solid 1px $orange;
  padding: 0 20px;
  width: 100%;

  .cash-pricing-type,
  .credit-pricing-type,
  .all-pricing-type {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    &.credit-pricing-type {
      padding-left: 20px;
    }

    .title {
      color: #263836;
      font-size: 1.6rem;
      height: 16px;
      line-height: 1;
    }

    .new-message {
      color: $orange;
      font-size: 2.4rem;
      line-height: 1.2;
    }
  }

  .divider {
    @include competitor-pricing-price-divider;
  }
}
