@import '~styles/variables';
@import '~styles/mixins';

.single-site-status {
  align-items: center;
  background-color: $grey;
  border-radius: 7px;
  color: $white;
  display: flex;
  font-size: 1.6rem;
  height: 24px;
  justify-content: center;
  margin-left: 20px;
  width: 57px;

  &.green {
    background-color: $station-status-green;
  }

  &.amber {
    background-color: $station-status-amber;
  }

  &.red {
    background-color: $station-status-red;
  }
}

.single-site-status-tooltip {
  @include tooltip-default-styles;

  .content {
    color: $light;
    display: flex;
    flex-direction: column;

    h3 {
      color: #383d41;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 10px 0;
    }
  }

  > div:last-child {
    min-width: 270px;
  }
}
