@import '~styles/variables';

.current-station-strategy {
  border-left: 2px solid #d5d9dd;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 50px;

  &.restricted {
    cursor: default;
  }

  .strategy-wrapper {
    display: flex;

    .strategy {
      /* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
      -webkit-box-orient: vertical;
      color: $tooltip-main-text;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      max-height: 50px;
      overflow: hidden;
      padding-left: 20px;
      padding-right: 10px;
      text-overflow: ellipsis;
      /* stylelint-enable property-no-vendor-prefix, value-no-vendor-prefix */

      span {
        color: $black-med;
        font-weight: bold;
        padding-right: 10px;

        &.restricted {
          color: $light;
          line-height: 2;
        }
      }
    }

    .edit-icon {
      align-items: center;
      display: flex;

      &.no-strategy {
        align-items: flex-start;
        margin-top: 3px;
      }
    }
  }
}
