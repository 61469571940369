@import '~styles/variables';

.filter-dropdown {
  align-items: center;
  align-self: center;
  background-color: $light-bg;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 57px;
  justify-content: space-between;
  margin-top: 10px;
  padding: 20px;
  position: relative;
  width: 100%;

  .filters {
    background-color: $white;
    border: 1px solid $green;
    border-radius: 10px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    .filter {
      align-items: center;
      border-bottom: 1px solid #ececec;
      color: $tooltip-main-text;
      display: flex;
      height: 57px;
      justify-content: space-between;
      padding: 20px;
    }
  }
}
