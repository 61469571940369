@import '~styles/variables';

.change-request-error {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  > div:first-of-type {
    color: $green;
    font-size: 2.4rem;
    line-height: 1.2;
  }

  > div:last-of-type {
    color: $light-red;
  }
}
