@import '~styles/variables';

.prices-form {
  color: $light;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.6rem;
  position: relative;

  .cancel-icon {
    cursor: pointer;
    position: absolute;
    right: -35px;
    top: -75px;
  }

  .time-dropdown {
    margin-top: 10px;
  }

  button {
    background-color: $green;
    color: $white;
    height: 44px;
    margin-top: 20px;

    &:hover {
      background-color: $green;
      color: $white;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.65;
    }
  }
}
