@import '~styles/variables';
@import '~styles/mixins';

.cash-price,
.credit-price {
  width: 50%;

  &.credit-price {
    padding-left: 20px;
  }

  .current-price {
    color: $dark;
    font-size: 1.6rem;
    height: 16px;
    line-height: 1;
  }

  .new-price {
    color: $orange;
    font-size: 2.4rem;
    line-height: 1.2;
  }

  .price {
    color: $tooltip-comparative-text;
    font-size: 2.4rem;
    line-height: 1.2;
    line-height: 1.2;
    min-height: 2.4rem;
  }

  .pricing-type {
    color: $dark;
    font-size: 1.6rem;
    line-height: 1;
  }
}

.divider {
  @include competitor-pricing-price-divider;
}
