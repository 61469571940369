@import '~styles/variables';

.grouped-table-body {
  background-color: $white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: $widget-box-shadow;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.loading {
    align-items: center;
    background-color: $white;
    flex: 1;
    justify-content: center;
  }

  &.editing-pricing {
    border-bottom-right-radius: 0;
    max-width: 60%;
  }
}
