@import '~styles/variables';

.price-change-footer {
  background-color: $white;
  bottom: 0;
  box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  height: 242px;
  left: $sidebar-width;
  position: fixed;
  width: 100%;
  z-index: 10;

  .main-content {
    display: flex;
    flex: 1;
    padding: 20px 5% 0;
  }

  .secondary-content {
    align-items: center;
    border-top: solid 1px #dcdcdc;
    display: flex;
    height: 90px;
    padding: 20px 5%;

    .station {
      color: $light;
      display: flex;
      flex: 1;
      flex-direction: column;
      line-height: 1.2;

      .station-name {
        color: $dark;
        font-size: 2.4rem;
      }
    }
  }

  .status-indicator {
    margin-right: 20px;
  }
}
