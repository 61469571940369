@import '~styles/variables';

.container {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.2;
}

.from-text {
  color: $tooltip-main-text;
}

.to-label {
  color: $light;
}

.to-text {
  color: $tooltip-main-text;
}
