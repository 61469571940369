@import '~styles/mixins';

.edit-target-margin-container {
  border-radius: 10px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.edit-target-margin-title {
  color: $edit-target-margin-title;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 18px;
}

.edit-target-margin-value {
  color: $light;
  font-size: 2.4rem;
  margin-bottom: 10px;
}

.edit-cancel-container {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  width: 100%;
}

.edit-cancel {
  margin-top: -40px;
  position: absolute;
  right: -42px;
}
