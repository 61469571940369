@import '~styles/variables';

.creator {
  align-items: center;
  background-color: #b8efca;
  border: 2px solid $green;
  border-radius: 7px;
  color: $green;
  display: flex;
  font-size: 1.6rem;
  height: 30px;
  max-width: calc(100% - 34px);
  padding: 0 6px;

  > svg {
    flex-shrink: 0;
    margin-right: 6px;
  }

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
