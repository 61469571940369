@import '~styles/variables';

.search {
  align-items: center;
  background-color: $stations-search-background;
  border-bottom: $thin-grey-border;
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 100%;
  padding: 0 3rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 30;

  @media screen and (max-width: $station-search-full-width) {
    transition: width 0.2s 0.6s;
  }

  &.visible {
    cursor: default;

    @media screen and (max-width: $station-search-full-width) {
      transition: width 0.2s;
      width: $main-width;
    }
  }

  &-logo {
    margin-right: 2rem;
  }

  &-text {
    background-color: inherit;
    border: 0;
    color: $dark;
    cursor: text;
    font-size: 1.8rem;
    outline: none;
    width: 100%;
  }

  .cancel {
    align-items: center;
    background-color: $red;
    border-radius: 100px;
    color: $primary-bg;
    cursor: pointer;
    display: none;
    font-size: 1.2rem;
    font-weight: bold;
    justify-content: center;
    min-height: 20px;
    min-width: 20px;
    text-align: center;

    &.visible {
      display: flex;
    }
  }
}
