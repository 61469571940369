@import '~styles/variables';
@import '~styles/mixins';

.previous-price {
  align-items: center;
  color: $green;
  display: flex;
  font-size: 1.6rem;

  &.negative {
    color: $pink-med;
  }

  .trend-icon {
    width: 20px;
    height: 21px;
  }
}

.previous-price-tooltip {
  @include tooltip-default-styles;
}
