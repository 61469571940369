@import '~styles/variables';
@import '~styles/mixins';

.invalid-station-popover {
  @include popover-default-styles;

  color: $tooltip-main-text;
  font-size: 1.6rem;
  width: 256px;

  .content {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;

    > div:last-of-type {
      color: $green;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
