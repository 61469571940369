@import '~styles/variables';
@import '~styles/mixins';

th.metric-header {
  > div {
    height: 100px;
    padding-top: 20px;

    p {
      color: $light;
      font-weight: normal;
      margin: 5px 0 0;
      padding: 0;
      
      &:first-of-type {
        color: #72818f;
        margin-top: 10px;
      }
    }
  }

  // stylelint-disable selector-class-pattern
  &.green {
    color: $green;

    &.netMargin {
      color: $purple;
    }

    &.fuelCardVolume {
      color: $purple;
    }

    &.bunkeredVolume {
      color: $blue;
    }

    &.netMarginAll {
      color: $purple;
    }

    &.netProfit {
      color: $purple;
    }

    &.businessProfit {
      color: $blue;
    }

    &.netProfitAll {
      color: $purple;
    }

    &.businessProfitAll {
      color: $blue;
    }

    &.purple {
      color: $purple;
    }

    &.blue {
      color: $blue;
    }
  }
  // stylelint-enable selector-class-pattern
}
