@import '~styles/variables';

.change-requests {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;

  > div {
    display: flex;
    flex: 1;
    padding-left: 50px;
    position: relative;
    width: 100%;

    &:first-of-type {
      border-bottom: 1px $grey-light solid;
    }

    .badge {
      align-items: center;
      background-color: $light-bg;
      border: 1px solid $dark;
      border-radius: 25px;
      bottom: 0;
      color: $dark;
      display: flex;
      font-size: 1.1rem;
      height: 15px;
      justify-content: center;
      left: -23px;
      margin: auto;
      position: absolute;
      top: 0;
      width: 46px;
    }
  }
}
