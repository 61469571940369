@import '~styles/variables';

.notifications {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(450px);
  transition: transform 0.4s linear;
  width: 450px;
  z-index: 40;

  &.visible {
    transform: none;
  }

  .body {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    padding: 30px 30px 185px;

    .notifications-spinner {
      margin: auto;
    }

    .clear-notifications-loading {
      align-items: center;
      color: $dark;
      display: flex;
      flex-direction: column;
      margin: auto;

      > h2 {
        color: $dark;
        margin-top: 35px;
      }
    }
  }

  .fallback {
    height: 100%;
    margin-bottom: 0;
    padding: 0;
  }
}

.clear {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 54%,
    #fff 54%
  );
  bottom: 0;
  height: 185px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;

  span {
    color: #b3bfc9;
    display: inline-block;
    margin-top: 125px;
    pointer-events: all;

    button {
      background: none;
      border: 0;
      color: #708191;
      outline: none;
      text-decoration: underline;
    }
  }
}
