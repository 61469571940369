@import '~styles/variables';

.table-header {
  background-color: $secondary-bg;
  color: $dark;
  display: flex;
  font-size: 1.6rem;
  height: 76px;

  &.editing-pricing {
    max-width: 60%;
  }
}
