@import '~styles/variables';

th.metric-footer {
  padding-top: 15px;

  > div {
    height: 50px;

    p {
      color: #72818f;
      font-weight: normal;
      margin: 0;
      padding: 0;
      
      &:nth-of-type(2) {
        color: $light;
        padding-top: 5px;

      }
    }
  }
}
