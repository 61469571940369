@import '~styles/variables';

.wrapper {
  .dialog-wrapper {
    @media screen and (min-width: 750px) {
      width: 720px;
    }
  }

  form {
    align-items: center;
    display: flex;
    justify-content: center;

    .header {
      margin: 10px 0 40px;
    }

    button {
      margin-bottom: 0;
      width: 310px;
    }
  }
}

.container {
  border: 1px solid $light-bg;
  color: $light;
  margin: 0 -30px 30px;
  max-height: 303px;
  overflow-y: auto;
  padding: 30px;

  > p {
    white-space: pre-line;
  }
}
