@import '~styles/variables';

.notifications {
  align-items: center;
  border-bottom: $thin-grey-border;
  border-left: $thin-grey-border;
  cursor: pointer;
  display: flex;
  flex: 0 0 80px;
  justify-content: center;
  position: relative;

  &:hover {
    background-color: $grey-light;
  }
}

.nubbin {
  background-color: #ff474b;
  border-radius: 2px;
  height: 4px;
  left: 28px;
  position: absolute;
  top: 28px;
  width: 4px;
}

.count {
  color: #72818f;
  font-size: 1.6rem;
  position: absolute;
  right: 9px;
  text-align: right;
  top: 9px;
}
