@import '~styles/variables';

.map {
  height: 50vh;
  min-height: 250px;
  margin-right: 40px;
  border-radius: 8px;
  box-shadow: 3px 3px 2px rgba($dark, 0.5);
  z-index: 0;
}
