@import '~styles/variables';

.cover {
  background-color: rgba($backdrop-background, 0.2);
  height: 100%;
  opacity: 0.9;
  position: absolute;
  width: 100%;
  z-index: 10;
}
