@import '~styles/variables';

.container {
  background-color: $dark-bg;
  display: flex;

  .main {
    flex: 1;
    min-width: $main-width-plus-sidebar;

    &-content {
      background-color: $light-bg;
      display: flex;
      flex-direction: column;
      min-height: $main-height;
      min-width: $main-content-min-width;
      padding: 25px;

      @media screen and (max-width: $main-content-full-width) {
        padding: 25px;
      }
    }
  }
}
