@import '~styles/variables';

.edit-pricing-footer {
  background-color: $white;
  bottom: 0;
  box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.11);
  color: $light;
  display: flex;
  height: 82px;
  justify-content: flex-end;
  left: $sidebar-width;
  position: fixed;
  width: calc(100% - #{$sidebar-width});
  z-index: 10;

  @media screen and (max-width: 1205px) {
    left: 0;
    width: 100%;
  }

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.2;
    margin-left: calc(5% + 30px);
    margin-right: 30px;

    @media screen and (max-width: $main-content-full-width) {
      margin-left: calc(1% + 30px);
    }

    &:not(.estimated-margin) {
      flex: 1;
    }

    .value {
      color: $dark;
      font-size: 2.4rem;
    }
  }

  .footer-margin {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
  }

  .fuel-grade-prices {
    background-color: inherit;
    display: flex;
    height: 100%;
    margin-right: 5%;
    overflow: hidden;
    padding-left: 30px;
    position: absolute;
    width: calc(90% * 0.4);

    @media screen and (max-width: $main-content-full-width) {
      margin-right: 1%;
      width: calc(98% * 0.4);
    }
  }

  .confirm {
    align-items: center;
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: calc(5% - 30px);
    width: 60px;

    &.invalid svg {
      cursor: not-allowed;
      fill: $red;
    }

    @media screen and (max-width: $main-content-full-width) {
      right: calc(1% - 20px);
    }

    svg {
      cursor: pointer;
    }
  }
}
