@import '~styles/variables';

.no-competitors {
  align-items: center;
  background-color: $light-bg;
  border-radius: 10px;
  display: flex;
  height: 390px;
  justify-content: center;
  margin-right: 40px;
  margin-top: 10px;

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 220px;
    max-width: 268px;
    text-align: center;

    h3 {
      color: $dark;
      font-size: 2.4rem;
    }

    p {
      color: $light;
      flex: 1;
      font-size: 1.6rem;
      padding-top: 15px;
    }

    .button {
      align-items: center;
      background-color: $green;
      border-radius: 10px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      color: $white;
      cursor: pointer;
      display: flex;
      font-weight: bold;
      height: 54px;
      justify-content: center;
      width: 140px;
    }
  }
}
