@import '~styles/variables';

.popover {
  max-width: none !important;
  right: 10px !important;
  z-index: 10 !important;
  display: flex !important;

  :global(.arrow) {
    display: none;
  }
}

.calendar {
  padding: 10px;
}

.period-dropdown {
  max-width: 26% !important;
}
