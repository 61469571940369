@import '~styles/variables';

.prices-information {
  display: flex;
  flex: 1;
  flex-direction: column;

  .price-info {
    border-top: 1px solid #eaeef2;
    display: flex;
    flex: 1.2;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;

    .date {
      align-items: center;
      color: $light;
      display: flex;
      font-size: 1.6rem;
      line-height: 1.4;
    }

    .price {
      align-items: center;
      color: $dark;
      display: flex;
      font-size: 2.4rem;
      line-height: 1.4;
    }

    .grade-source {
      align-items: center;
      display: flex;
      flex: 1;

      .previous-price {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
    }
  }
}
