// block Colours
$light-bg: #f6f8fa;
$primary-bg: #fff;
$secondary-bg: #ebedef;
$tertiary-bg: #e0e3e6;
$dark-bg: #24303b;

// UI Colours
$green: #39c258;
$green-med: #c6e9cf;
$green-med-light: #d7f3de;
$green-light: #e8f9ed;
$orange: #ff6641;
$orange-light: #ffcbbe;
$pink: #ec6278;
$pink-light: #f8d7dd;
$pink-med: #ff5676;
$red: #ff474b;
$light-red: #fe4850;
$white: #fff;
$black: #000;
$black-med: #383d41;
$grey: #b2bfca;
$grey-light: #eee;
$grey-vlight: #eaeaea;
$grey-ulight: #ebebeb;
$grey-uulight: #ececec;
$grey-uuulight: #f7f7f7;
$purple: #7c44d2;
$purple-light: #d8c7f2;
$blue: #4fc4d1;
$blue-light: #caedf1;
$yellow: #f5ce0a;

// disabled
$inactive: #D3DAE0;

// nuance Colours
$dark-grey: #d5d9dd;
$gold-primary: #e0be79;
$gold-secondary: #f9f3e6;

// type Colours
$light: #b3bfc9;
$dark: #73818e;
$extra-dark: #23303c;
$disabled: #e3e6e8;

// grade Colours
$grade-one-primary: #39c258;
$grade-one-scondary: #c3f5d6;
$grade-two-primary: #008d4d;
$grade-two-secondary: #c5ead9;
$grade-three-primary: #006c8a;
$greade-three-secondary: #cfe7ed;
$grade-four-primary: #263836;
$grade-four-secondary: #d4dad9;

// tooltips
$tooltip-main-text: #72818f;
$tooltip-comparative-text: #383d41;

// backdrops
$backdrop-background: #aab0b7;

// widget specific colours
$stations-search-background: #fcfcfc;
$edit-target-margin-title: #383d41;
$copyright-text: #b3bfc9;

// placeholder
$placeholder: #b3bfc9;

// station title
$station-title: #73818e;

// buysmart
$buysmart-days-left: #72818f;
$buysmart-warning: #ff7756;
$buysmart-stockout: #ff5676;

// pricing
$pricing-no-cash-credit: #4fc4d1;

// competitor pricing
$competitor-pricing-my-station: $dark-grey;
$competitor-pricing-competitor-station: $light-bg;
$competitor-pricing-favourite: #f9f3e6;
$competitor-pricing-favourite-icon: #e0be79;

// station status
$station-status-black: #434f59;
$station-status-red: #ff5676;
$station-status-amber: #f4a522;
$station-status-green: #39c258;

// scroll bar
$track: #f5f5f5;

// borders
$thin-grey-border: 1px solid $grey-light;

// payment fallback
$payment-fallback-rect: #d4d8dc;

// generic widget styles
$widget-border-radius: 10px;
$widget-box-shadow: 0 10px 14px 0 rgba($dark, 0.1);

// generic widget spacing
$widget-margin-bottom: 40px;

// heights and widths
$header-height-component: 80px;
$sidebar-width: 80px;
$main-height: calc(100vh - #{$header-height-component*2});
$main-width: calc(100vw - #{$sidebar-width});
$main-content-min-width: 1040px;
$main-width-plus-sidebar: calc(#{$main-content-min-width} + #{$sidebar-width});

// responsive styles
$station-search-full-width: 1024px;
$main-content-full-width: 1525px;

// settings menu
$settings-menu-dark: #383d41;
$settings-menu-light: #718190;
