@import '~styles/variables';

.pole-prices-row {
  background-color: $white;
  border-bottom: 1px $grey-light solid;
  display: flex;
  height: 81px;
  padding-left: 30px;
  position: relative;

  &:hover .fuel-grade-differentials {
    display: flex;
  }

  &.first-row:not(.filtered) .fuel-grade-differentials {
    height: 76px;
    top: -76px;
  }

  &.first-row.filtered .fuel-grade-differentials {
    height: 80px;
    top: -80px;
  }

  .fuel-grade-differentials {
    align-items: center;
    background-color: $white;
    border-bottom: 1px $grey-light solid;
    color: $dark;
    display: none;
    flex: 1;
    height: 81px;
    left: 0;
    padding-left: 30px;
    position: absolute;
    top: -81px;
    width: 100%;
    z-index: 1;

    > div:first-of-type {
      margin-right: 10px;
    }

    &:hover {
      display: none;
    }
  }
}
