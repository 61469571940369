@import '~styles/variables';

.item {
  align-items: center;
  border-bottom: 1px solid $grey-uulight;
  display: flex;
  height: 57px;
  justify-content: space-between;
  padding: 20px;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  * {
    pointer-events: none;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.active-item > span {
    color: $green;
  }
}
