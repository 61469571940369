@import '~styles/mixins';

.metric-container {
  background-color: $light-bg;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 70px;
  margin-bottom: 10px;
  max-width: 600px;
  min-width: 270px;
  padding: 14px 20px;
}

.metric-stretch {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.metric-title {
  color: $light;
  font-size: 16px;
  line-height: 1;
}

.metric-value {
  color: $dark;
  font-size: 24px;
  line-height: 1.3;

  &.placeholder-margin {
    margin-top: 5px
  }
}
