@import '~styles/variables';

.fuel-grade-differential {
  align-items: center;
  border-radius: 7px;
  color: $white;
  display: flex;
  height: 38px;
  justify-content: center;
  margin-left: 5px;
  position: relative;
  width: 45px;
}
