@import '~styles/variables';

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .delete-spinner {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delete {
    align-items: center;
    background-color: $white;
    border-style: none;
    color: $pink-med;
    display: flex;
    flex-direction: row;

    font-size: 1.6rem;
    font-weight: 500;
    justify-content: center;
    letter-spacing: normal;
    text-decoration: underline;
    width: 150px;

    &:focus {
      outline: none;
    }

    &.hidden {
      visibility: hidden;
    }
  }

  .save {
    align-items: center;
    background-color: $dark-grey;
    border-radius: 12px;
    border-style: none;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    font-size: 1.6rem;
    height: 44px;
    justify-content: center;
    max-width: 150px;
    width: 50%;

    &.disabled-save {
      cursor: not-allowed;
      opacity: 0.65;
    }
  }
}
