@import '~styles/variables';

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .cancel {
    align-items: center;
    color: $pink-med;
    display: flex;
    font-size: 1.6rem;
    justify-content: center;
    line-height: 1.38;
    width: 115px;

    span {
      cursor: pointer;
    }
  }

  .apply {
    align-items: center;
    align-self: center;
    background-color: $light-bg;
    border-radius: 10px;
    color: $light;
    cursor: not-allowed;
    display: flex;
    font-weight: bold;
    height: 57px;
    justify-content: space-between;
    opacity: 0.65;
    padding: 20px;
    width: 115px;

    &.valid {
      background-color: $green;
      color: $white;
      cursor: pointer;
      opacity: 1;

      svg path {
        fill: $white;
      }
    }
  }
}
