@import '~styles/variables';
@import '~styles/mixins';

.grade-container {
  border-radius: 10px;
  height: 70px;
  padding: 14px 20px;
}

.grade-title {
  color: $white;
  font-size: 1.6rem;
  line-height: 1;
}

.grade-volume {
  color: $white;
  font-size: 2.4rem;
}

.info-icon {
  margin-left: 5px;
  margin-top: -3px;
}

.info-icon-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 270px;
  }
}
