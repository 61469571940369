@import '~styles/mixins';

.profit-selector-title {
  color: $dark;
  font-size: 2.4rem;
  margin-bottom: 40px;
}

.profit-selector {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.profit-selector-text {
  color: $dark;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
}

.icon {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  margin-right: 10px;
}
