@import '~styles/variables';

.header-row {
  background-color: $secondary-bg;
  color: $dark;
  display: flex;
  font-size: 1.6rem;
  height: 76px;
  padding-left: 30px;

  .fuel-grade-cell {
    align-items: center;
    color: $dark;
    display: flex;
    flex: 1;
    flex: 1 0 140px;
    font-weight: bold;
  }
}
