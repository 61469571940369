@import '~styles/variables';

.tank-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  width: 150px;
}

.days-left-info {
  color: $buysmart-days-left;
  margin: 0 0 38px;

  &.warning {
    color: $buysmart-warning;
  }

  &.stockout {
    color: $buysmart-stockout;
  }

  .days {
    font-size: 5rem;
    line-height: 0.9;
    text-align: center;
  }

  .info {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
  }
}

.tank-volume-container {
  display: flex;
  flex-direction: column;

  .ullage {
    color: $light;
    font-size: 1.6rem;
    text-align: center;
  }

  .volume {
    color: $dark;
    font-size: 2.4rem;
    text-align: center;
  }
}

.tank {
  font-size: 1.6rem;
  height: 240px;
  margin-bottom: 37px;
  text-align: center;
  width: 100px;

  .tank-outline {
    height: 238px;
    width: 98px;
  }

  .group {
    fill: none;
    stroke: none;
    stroke-width: 1px;
  }

  .max-volume {
    fill: $light;
  }

  .max-volume-label {
    fill: $light;
    font-size: 1rem;
  }

  .grade-tank-info {
    fill: $white;

    &.tank-low {
      fill: $dark;
    }
  }
}
