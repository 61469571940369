@import '~styles/variables';

.settings {
  align-items: center;
  border-bottom: $thin-grey-border;
  border-left: $thin-grey-border;
  cursor: pointer;
  display: flex;
  flex: 0 0 80px;
  justify-content: center;
  min-width: 0;

  &:hover {
    background-color: $grey-light;
  }
}

.user-row {
  align-items: flex-start !important;
  padding-top: 20px;

  .user-name {
    font-size: 1.6rem;
    line-height: 112.5%;
    max-width: 190px;

    .name {
      color: $settings-menu-dark;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .company-name {
      color: $settings-menu-light;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.settings-popover {
  top: -15px !important;

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    padding: 0 20px;
    width: 246px;

    .row {
      align-items: center;
      color: $settings-menu-light;
      cursor: pointer;
      display: flex;
      height: 65px;
      padding-left: 10px;

      svg {
        flex: 0.3 0 nowrap;
      }

      > div {
        flex: 1 0 nowrap;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid #f6f8fa;
      }

      &.sign-out {
        color: $pink-med;

        svg path {
          fill: $pink-med;
        }
      }

      .title {
        flex: 1;
        padding-left: 20px;
        white-space: nowrap;
      }
    }
  }
}
