@import '~styles/variables';

.price-information {
  display: flex;
  flex: 1;
  flex-direction: column;

  .price-info {
    border-top: 1px solid #eaeef2;
    display: flex;
    flex: 1.2;
    flex-direction: column;
    justify-content: center;

    .date {
      align-items: center;
      color: $light;
      display: flex;
      font-size: 1.6rem;
      line-height: 1.4;

      .no-cc-price {
        color: $pricing-no-cash-credit;
      }
    }

    .price {
      align-items: center;
      color: $dark;
      display: flex;
      font-size: 2.4rem;
      line-height: 1.4;
    }
  }

  .grade-source-info {
    align-items: center;
    border-top: 1px solid #eaeef2;
    display: flex;
    flex: 1;

    &.no-cc {
      border-bottom: 1px solid #eaeef2;
      border-top: 0;
    }
  }
}

.no-cc-message {
  color: $pricing-no-cash-credit;
  font-size: 1.6rem;
  padding: 10px 0;
}
