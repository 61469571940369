@import '~styles/mixins';

.nav-tab {
  background: $stations-search-background;
  border-radius: $widget-border-radius;
  cursor: pointer;
  display: flex;
  flex-basis: 0;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 20px 20px 15px;
  z-index: 0;
}

.nav-tab-first-tab {
  background: $stations-search-background;
  border-radius: $widget-border-radius;
  cursor: pointer;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 0px;
  padding: 20px 20px 15px;
  z-index: 0;
}

.nav-tab-active {
  background-color: $primary-bg;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  margin-left: 10px;
  padding: 20px 20px 15px;
  width: 100%;
  cursor: pointer;
}

.nav-tab-active-first-tab {
  background-color: $primary-bg;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  margin-left: 0px;
  padding: 20px 20px 15px;
  width: 100%;
  cursor: pointer;
}

.tab-nav-metric-title {
  color: $light;
  font-size: 1.8rem;
  font-style: normal;
  white-space: nowrap;
}
