@import '~styles/variables';

.wrapper {
  form {
    .header {
      margin: 0;
      padding: 0;
    }

    h1 {
      color: $dark;
      font-size: 2.4rem;
      margin: 0;
      text-align: center;
    }

    p {
      color: $light;
      font-size: 1.6rem;
      line-height: 137.5%;
      margin: 19px 26px 50px;
      text-align: center;
    }

    button {
      margin-bottom: 0;
    }
  }
}

.container {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;

  svg {
    margin: 20px 0 50px;
  }
}
