@import '~styles/variables';

.toggle-container {
  align-items: center;
  display: flex;

  &.disabled {
    opacity: 0.4;

    .toggle {
      cursor: not-allowed;
    }

    .label {
      cursor: not-allowed;
    }
  }

  .checkbox {
    height: 0;
    visibility: hidden;
    width: 0;
  }

  .toggle {
    align-items: center;
    background: $grey;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: space-between;
    margin: 0;
    margin-right: 8px;
    position: relative;
    transition: background-color 0.2s;
    width: 52px;

    .toggle-button {
      background: $primary-bg;
      border-radius: 6px;
      box-shadow: $widget-box-shadow;
      height: 22px;
      left: 4px;
      position: absolute;
      top: 5px;
      transition: 0.2s;
      width: 22px;
    }

    &-selected {
      background-color: $green;
    }
  }

  .checkbox:checked + .toggle .toggle-button {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }

  .label {
    color: $grey;
    cursor: pointer;
    font-weight: 500;

    &-selected {
      color: $green;
    }
  }
}
