@import '~styles/variables';
@import '~styles/mixins';

.icon {
  margin-right: 17px;
  margin-top: -6px;
}

.tooltip {
  @include tooltip-default-styles;

  cursor: default;
  font-size: 1.6rem;
}

.tooltip-content {
  text-align: center;
}
