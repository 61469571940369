@import '~styles/variables';

.price-change-footer {
  background-color: $white;
  bottom: 0;
  box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  height: 242px;
  left: $sidebar-width;
  position: fixed;
  width: calc(100% - #{$sidebar-width});
  z-index: 10;

  &.prices-sent {
    height: 90px;

    .secondary-content {
      border-top: 0;
    }
  }

  .main-content {
    display: flex;
    flex: 1;
    padding: 20px 5% 0;
  }

  .secondary-content {
    align-items: center;
    border-top: solid 1px #dcdcdc;
    display: flex;
    height: 90px;
    padding: 20px 5%;

    .stations {
      color: $light;
      display: flex;
      flex-direction: column;
      line-height: 1.2;
      width: 350px;

      .stations-count {
        color: $dark;
        font-size: 2.4rem;
      }
    }

    .margin-info-container {
      display: flex;
      flex: 4;
      flex-direction: column;
      justify-content: center;

      .margin-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 500px;
      }
    }
  }
}
