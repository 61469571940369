@import '~styles/variables';

.metric-cell {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  .today {
    align-items: center;
    color: $dark;
    display: flex;
    font-size: 1.6rem;
    min-height: 3.6rem;

    .value {
      flex: 0 0 110px;
      font-size: 2.4rem;
    }
  }

  .comparative {
    align-items: center;
    color: $light;
    display: flex;
    font-size: 1.6rem;
    height: 2.3rem;
  }
}
