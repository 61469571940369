.station-checkbox {
  align-items: center;
  cursor: default;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 80px;

  &.invalid svg {
    cursor: not-allowed;
  }

  svg {
    cursor: pointer;
  }
}
