@import '~styles/variables';

.resizable {
  background-color: transparent;
  border-bottom-right-radius: 8px;
  border-left: 1px #dcdcdc solid;
  box-shadow: $widget-box-shadow;
  position: absolute !important;
  right: 0;
  top: 0;
  z-index: 5;

  &.loading {
    box-shadow: none;
  }

  .wrapper {
    background-color: #dfe3e6;
    border-top-right-radius: 8px;
    overflow: hidden;
    position: relative;

    .first-row {
      border-bottom: 1px #cfd6db solid;
      border-top-right-radius: 8px;
      height: 60px;
    }
  }
}
