@import '~styles/variables';

.station-cell {
  border-left: 2px solid $green;
  border-right: 1px #768491 dashed;
  display: flex;
  flex: 300px 0 0;
  flex-direction: column;
  padding-right: 30px;

  &.error {
    border-left: 2px solid $light-red;
  }

  > div {
    align-items: center;
    display: flex;
    height: 82px;
    padding-left: 30px;

    span {
      padding-left: 15px;
    }
  }
}
