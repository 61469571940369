@import '~styles/variables';
@import '~styles/mixins';

.current-price {
  color: $dark;
  font-size: 1.6rem;
  height: 16px;
  line-height: 1;
}

.price-info {
  align-items: center;
  display: flex;

  .price-value {
    flex: 1;
    font-size: 2.4rem;
    line-height: 1.2;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 36px;

    svg {
      cursor: pointer;
    }
  }
}
