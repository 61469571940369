@import '~styles/variables';
@import '~styles/mixins';

.cash-discount-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    display: flex;
    flex-direction: column;

    > div:first-child {
      color: $black-med;
    }
  }
}
