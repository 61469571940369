@import '~styles/variables';

.single-site {
  align-items: center;
  border-bottom: $thin-grey-border;
  display: flex;
  flex: 0 0 40%;
  padding: 0 30px;

  > div:first-of-type {
    padding-right: 20px;
  }

  > div:nth-of-type(2) {
    color: $dark;
    font-size: 2.4rem;
  }
}
