@import '~styles/variables';

.company-performance-fallback {
  align-items: center;
  color: $light;
  display: flex;
  justify-content: center;
  height: $header-height-component;
  background-color: $primary-bg;
}
