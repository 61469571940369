@import '~styles/mixins';

.key-metrics-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
}

.key-metrics-title {
  color: $dark;
  font-size: 24px;
  margin-bottom: 40px;
}
