@import '~styles/variables';

.logo {
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  left: 50px;
  position: fixed;
  top: 50px;
  width: 64px;
  z-index: 0;
}

.background {
  align-items: center;
  background-attachment: fixed;
  background-color: #a5dcdf;
  background-image: url('../../images/background.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100vw;

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
    width: 340px;
    z-index: 10;

    .title {
      font-size: 2.4rem;
    }

    .text {
      color: $dark;
      font-size: 1.6rem;
      text-align: center;
    }
  }

  .background-text {
    color: rgba(0, 0, 0, 0.02);
    font-size: 25rem;
    font-weight: bolder;
    left: 50%;
    letter-spacing: 0.5rem;
    line-height: 0.9;
    padding-bottom: 80px;
    position: absolute;
    text-align: center;
    top: 45%;
    transform: translate(-50%, -50%);
    z-index: 0;

    @media screen and (max-width: 1350px) {
      font-size: 20rem;
    }

    @media screen and (max-width: 830px) {
      font-size: 15rem;
    }
  }
}
