@import '~styles/variables';
@import '~styles/mixins';

.modal {
  @include modal-default-styles;

  > div > div > div {
    .icon {
      margin-top: 40px;
    }

    .body {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-top: 40px;
      width: 70%;

      h4 {
        color: $dark;
        font-size: 2.4rem;
        margin-bottom: 15px;
      }

      p {
        color: $light;
      }
    }

    .footer {
      display: flex;
      width: 100%;

      > div {
        flex: 1;
        height: 54px;
        margin-bottom: 20px;

        &.cancel {
          align-items: center;
          display: flex;
          justify-content: center;

          // stylelint-disable-next-line selector-max-compound-selectors
          p {
            color: $light;
            cursor: pointer;
            margin-bottom: 0;
          }
        }

        // stylelint-disable-next-line selector-max-compound-selectors
        &.confirm button {
          background-color: $red;
          border-style: none;
          color: $white;
          height: 100%;
          width: 140px;

          // stylelint-disable-next-line selector-max-compound-selectors
          &:active {
            background-color: $red;
          }
        }
      }
    }
  }
}
