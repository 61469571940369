@import '~styles/variables';
@import '~styles/mixins';

.edit-pricing-button {
  align-items: center;
  background-color: $tooltip-main-text;
  border-radius: 10px;
  color: $white;
  cursor: pointer;
  display: flex;
  flex: 190px 0 0;
  font-weight: 500;
  height: 57px;
  justify-content: center;

  &.disabled {
    background-color: $light-bg;
    color: #b0bfca;
    cursor: not-allowed;

    svg path {
      fill: #b0bfca;
    }
  }

  &.cancel {
    background-color: $pink-med;
  }

  span {
    padding-left: 10px;
  }
}

.price-change-disabled-tooltip {
  @include tooltip-default-styles;

  div {
    text-align: center;
  }
}
