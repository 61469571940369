@import '~styles/variables';
@import '~styles/mixins';

.my-station-cell {
  @include competitor-pricing-cell;

  align-items: center;
  background-color: $competitor-pricing-my-station;
  color: $dark;
  display: flex;
  flex: 380px 1 0;
  min-width: 420px;
  width: 100%;

  &.no-cash-credit {
    border: solid 1px $pricing-no-cash-credit;
  }

  .station-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    line-height: 1.2;
    margin-left: 20px;

    .station-name {
      color: $tooltip-comparative-text;
      font-size: 2.4rem;
    }
  }
}

.no-cash-credit-tooltip {
  @include tooltip-default-styles;
}
