.slider-arrow-container {
  align-items: flex-end;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  .slider-arrow {
    padding-left: 1rem;
  }
}
