@import '~styles/variables';

.status-information {
  > div:first-of-type {
    align-items: center;
    border-top: 1px solid #edeff0;
    display: flex;
    padding: 15px 0;

    h4 {
      color: $dark;
      flex: 1;
      font-size: 1.6rem;
      margin: 0;
    }

    .status-badge {
      align-items: center;
      border-radius: 8.5px;
      color: $white;
      display: flex;
      font-size: 1.2rem;
      justify-content: center;
      width: 62px;

      &.green {
        background-color: $station-status-green;
      }

      &.amber {
        background-color: $station-status-amber;
      }

      &.red {
        background-color: $station-status-red;
      }

      &.black {
        background-color: $station-status-black;
      }
    }
  }

  > div:last-of-type {
    padding-bottom: 10px;
  }
}
