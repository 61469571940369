@import '~styles/variables';
@import '~styles/mixins';

.modal {
  @include modal-default-styles;

  > div {
    width: 400px;

    > div {
      height: 400px;

      > div {
        .icon {
          margin-top: 40px;
        }

        .body {
          display: flex;
          flex: 1;
          flex-direction: column;
          padding-top: 40px;
          width: 85%;

          h4 {
            color: $dark;
            font-size: 2.4rem;
            margin-bottom: 20px;
          }

          p {
            color: $light;
          }
        }

        .buttons {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          width: 85%;

          .button {
            background-color: $green;
            color: $white;
            flex: 155px 0 0;
            height: 54px;

            &.dismiss {
              background-color: $dark-grey;
              color: $dark;
            }
          }
        }
      }
    }
  }
}
