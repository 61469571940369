@import '~styles/variables';

.table {
  tr td:not(.padding-cell),
  tr th:not(.padding-cell) {
    border-top: 1px solid $light-bg;
  }

  tr.weekend td {
    background-color: #fbfcfd;
    border-bottom: 1px solid #dde4ea;
    border-top: 1px solid #dde4ea;
  }

  td.padding-cell,
  th.padding-cell {
    width: 40px;
  }

  th {
    color: $dark;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1;
  }

  td {
    color: $dark;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1;
  }

  tbody {
    td {
      padding: 18px 0;
    }

    tr.highlight {
      td {
        color: $green;
      }
    }
  }
}
