@import '~styles/variables';

.table-options {
  background-color: #dfe3e6;
  border-bottom: 1px #cfd6db solid;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: $widget-box-shadow;
  display: flex;
  height: 60px;

  &.restricted {
    cursor: not-allowed;
  }
}
