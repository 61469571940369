@import '~styles/variables';

.svg-hover {
  fill: $light;
  height: 20px;
}

.selected-circle-inner {
  fill: $buysmart-days-left;
}

.hovered-circle {
  fill: $buysmart-days-left;
}
