@import '~styles/variables';

.value-input {
  align-items: center;
  align-self: center;
  background-color: $light-bg;
  border-radius: 10px;
  display: flex;
  height: 57px;
  justify-content: space-between;
  margin-top: 10px;
  padding: 20px;
  position: relative;
  width: 100%;

  input {
    background-color: inherit;
    border: 0;
    color: inherit;
    cursor: pointer;
    height: 57px;
    outline: none;
    width: 100%;
  }
}
