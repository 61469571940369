@import '~styles/variables';

.title-row {
  background-color: $secondary-bg;
  color: $dark;
  display: flex;
  font-weight: bold;
  height: 76px;

  &.prices-sent > div:nth-of-type(2) > div > div:first-of-type {
    color: $green;
  }

  > div:first-of-type {
    align-items: center;
    border-right: 1px dashed #768491;
    display: flex;
    flex: 300px 0 0;
    padding-left: 30px;
  }

  > div:nth-of-type(2) {
    align-items: center;
    display: flex;
    flex: 1;
    padding-left: 50px;

    > div {
      color: $black-med;

      > div:last-of-type {
        color: $orange;
      }
    }
  }
}
