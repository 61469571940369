@import '~styles/variables';

.edit-price-button {
  flex: 1;

  > div {
    align-items: center;
    background-color: $light;
    border-radius: 5px;
    color: $tooltip-comparative-text;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    font-weight: 500;
    height: 18px;
    justify-content: center;
    margin-left: 5px;
    width: 65px;

    &.restricted {
      background-color: $light-bg;
      color: #b0bfca;
      cursor: not-allowed;
    }
  }
}
