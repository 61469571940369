@import '~styles/variables';

.fuel-grades-row {
  background-color: #dfe3e6;
  border-bottom: 1px #cfd6db solid;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: $widget-box-shadow;
  color: #768491;
  display: flex;
  font-weight: bold;
  height: 60px;

  > div:first-of-type {
    flex: 300px 0 0;
  }

  > div:nth-of-type(2) {
    align-items: center;
    display: flex;
    flex: 1;
    padding-left: 50px;

    .fuel-grade {
      flex: 1;
    }
  }
}
