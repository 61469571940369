@import '~styles/variables';

.price-date {
  color: $dark;
  font-size: 1.6rem;
  height: 16px;
  line-height: 1;
}

.price-value {
  color: $tooltip-comparative-text;
  font-size: 2.4rem;
  line-height: 1.2;
}

.current-price {
  color: $dark;
  font-size: 1.6rem;
  height: 16px;
  line-height: 1;
}

.new-price {
  color: $orange;
  font-size: 2.4rem;
  line-height: 1.2;
}
