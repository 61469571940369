@import '~styles/variables';

.selected-filters {
  align-items: center;
  display: flex;

  .remove-all-button {
    align-items: center;
    background-color: $light-red;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;
  }
}
