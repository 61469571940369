@import '~styles/variables';

.textarea {
  background-color: $light-bg;
  border: 0;
  border-radius: 10px;
  color: $dark;
  cursor: pointer;
  font-size: 1.6rem;
  min-height: 114px;
  outline: none;
  padding: 20px;
  resize: none;
  width: 100%;

  &::placeholder {
    color: $light;
  }
}
