@import '~styles/mixins';

.filter {
  align-items: center;
  border-right: 1px #cfd6db solid;
  color: $dark;
  display: flex;
  font-weight: 500;
  justify-content: center;
  min-width: 150px;
  padding-right: 25px;

  &.restricted {
    @include restricted;
  }

  .filter-icon {
    path {
      fill: $light;
    }
  }

  .title {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    padding: 0 25px;

    &.restricted {
      @include restricted;
    }

    .filter-text {
      margin-left: 15px;

      &.restricted {
        @include restricted;
      }
    }
  }
}
