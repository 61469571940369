@import '~styles/variables';

.pending-change-requests {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;

  > div {
    display: flex;
    flex: 1;
    padding-left: 50px;
    position: relative;
    width: 100%;

    &:first-of-type {
      border-bottom: 1px $grey-light solid;
    }
  }
}
