@import '~styles/variables';

.confirm-price-changes {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: $widget-box-shadow;
  display: flex;
  flex-direction: column;
  margin-bottom: 200px;
  overflow: hidden;

  &.loading {
    flex: 1;
  }

  &.no-changes {
    flex: 1;
  }

  .loading {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  }
}
