@import '~styles/mixins';

.first-load {
  @include widget-default-styles;

  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 719px;
  padding: 40px 0 40px 40px;
}

.container {
  position: relative;

  .loading {
    @include widget-default-styles;

    background-color: rgba($primary-bg, 0.75);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
  }
}
