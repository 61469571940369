.station-link {
  align-items: center;
  color: #72818f;
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;

  svg {
    height: 16px;
    margin-right: 10px;
    width: 16px;
  }

  &.next svg {
    margin-left: 10px;
  }
}
