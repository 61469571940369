@import '~styles/variables';

.container {
  cursor: default;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  user-select: none;
}

.nav-button {
  align-items: center;
  background: $dark;
  border-radius: 20px;
  border-style: none;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  height: 40px;
  justify-content: center;
  outline: none;
  width: 40px;
}

.nav-button.disabled {
  background: $disabled;
  cursor: not-allowed;
}

.next-button {
  .arrow {
    transform: rotate(180deg);
  }
}

.month-year {
  color: $tooltip-main-text;
  flex: 0 0 auto;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.06;
}

.header-row {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 20px 21px 19px;
}

.table {
  flex: 0 0 auto;
}

.weekdays {
  border-bottom: 1px solid $grey-uulight;
  border-top: 1px solid $grey-uulight;
  color: $light;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.13;
}

.weekday {
  padding: 10px 0;
  text-align: center;
}

.days {
  border-bottom: 1px solid $primary-bg;

  &:last-child {
    border-bottom-color: $grey-uulight;
  }
}

.day {
  color: $light;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500;
  height: 58px;
  line-height: 0.88;
  min-width: 55px;
  text-align: center;

  &.day-in-month {
    color: $tooltip-main-text;
  }

  &.disabled {
    color: $light;
    cursor: not-allowed;
  }

  &.active {
    background: $green-med-light;
    color: $tooltip-comparative-text;
  }

  &.first-active {
    background: $green;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    color: $primary-bg;
  }

  &.last-active {
    background: $green;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    color: $primary-bg;
  }
}

.header-label-row {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: center;
  color: $tooltip-main-text;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.06;
  margin-top: 15px;
}
