.select-boxes {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 40px;
  
    > * {
      flex: 1 1 35%;
      max-width: 20%;
      height: 40px;
  
      + * {
        margin-left: 10px;
      }
    }
  }
