.reminders {
  .title {
    align-items: center;
    display: flex;
    margin-bottom: 15px;

    svg {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .toggles {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: 80px;
    max-width: 1000px;

    > div {
      margin-right: 50px;
    }
  }
}
