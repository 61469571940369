@import '~styles/variables';

.container {
  align-items: center;
  background-color: $light-bg;
  bottom: 0;
  color: $copyright-text;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  justify-content: center;
  padding: 10px 10px 30px;
  z-index: 100;
}
