@import '~styles/variables';

.company-performance {
  align-items: center;
  display: flex;
  height: $header-height-component;
  background-color: $primary-bg;

  &.loading {
    justify-content: center;
  }
}
