@import '~styles/variables';
@import '~styles/mixins';

.sidebar-item {
  align-items: center;
  background-color: rgba($primary-bg, 0.06);
  cursor: pointer;
  display: flex;
  flex: 0 0 80px;
  justify-content: center;
  margin-bottom: 1px;

  &.disabled {
    background-color: rgba($primary-bg, 0);
    cursor: not-allowed;

    .icon path {
      fill: #3d4854;
    }
  }

  &:not(.disabled):hover {
    background-color: rgba($primary-bg, 0.2);
    transition: background-color 0.5s;

    .icon path {
      fill: $green;
      transition: fill 0.5s;
    }
  }

  &-active {
    background: linear-gradient(
      90deg,
      rgba($dark-bg, 1) 14%,
      rgba($green, 0.27) 100%
    );
    border-left: 2px solid $green;

    .icon {
      transform: translateX(-1px);

      path {
        fill: $green;
      }
    }
  }
}

.analytics-tooltip {
  @include tooltip-default-styles;

  left: -15px !important;
  width: 148px;

  .content {
    text-align: center;

    span:first-of-type {
      color: $dark-bg;
    }

    span:last-of-type {
      color: $green;
    }
  }
}
