@import '~styles/mixins';

.slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  scroll-snap-type: x mandatory;

  .slider-cover {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.001) 0%,
      $white 100%
    );
    height: 100%;
    position: absolute;
    right: 0;
    width: 25px;
    z-index: 1;
  }

  .slider {
    display: flex;
    flex-direction: row;
    -webkit-overflow-scrolling: touch;
  }
}
