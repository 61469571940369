@import '~styles/variables';
@import '~styles/mixins';


.footer {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 30px;
}

.confirm-button {
  background: $green;
  color: $white;
  font-size: 1.6rem;
  font-weight: bold;
  height: 54px;
  line-height: 1.38;
  width: 163px;
}

.calendars {
  display: flex;
  flex-wrap: column;
  max-height: 570px;
  overflow: auto;
}

.calendar {
  flex: 1 0 auto;
  margin: 10px 0;
}

@media (max-width: 2000px) {
  .calendars {
    flex-direction: column;
    max-height: 530px;
  }
}

.calendar {
  padding-right: 10px;
}

.tooltip {
  @include tooltip-default-styles;

  cursor: default;
  font-size: 1.6rem;
}

.tooltip-content {
  text-align: center;
}
