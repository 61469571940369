@import '~styles/variables';

.popup-wrapper {
  & > div:first-of-type > div:first-of-type {
    margin: 0px 0px 0px 0px;
    min-width: 224px;
    max-width: 244px;
  }
}

.popup {
  margin: 2px 2px 2px 2px;
  min-width: 220px;
  max-width: 240px;

  .title-row {
    display: flex;

    .station-info {
      padding-left: 4px;
      align-content: center;
      font-weight: 500;

      .distance {
        font-size: small;
        color: $light;
      }

      .station-name {
        font-size: large;
        color: $station-title;
      }
    }
  }

  .influencer-title-row {
    display: flex;
    background-color: $gold-secondary;
    border: 2px solid $gold-primary;
    border-radius: 10px;
    margin-bottom: 5px;

    .station-info {
      align-content: center;
      font-weight: 500;

      .distance {
        font-size: small;
        color: $light;
      }

      .station-name {
        font-size: large;
        color: $station-title;
      }
    }
  }

  .cash-credit-price-row {
    display: flex;
    width: 238px;
    padding-left: 4px;
    padding-right: 4px;
    transition: all 0.25s ease-in-out;
    border: 1px solid transparent;

    .fuel-grade-img {
      display: flex;
      flex-wrap: wrap;
      align-self: center;
      align-content: center;
      justify-content: center;
      font-size: medium;
      width: 40px;
      height: 30px;
      border-radius: 8px;
      color: $white;
      font-weight: 500;
      margin-right: 12px;
    }

    .price-info {
      margin: 3px;
      justify-content: left;
      align-content: center;
      font-weight: 500;
      width: 170px;

      .date {
        font-size: small;
        color: $light;
      }

      .selected-fg-date {
        font-size: small;
        color: $tooltip-main-text;
      }

      .price {
        display: flex;
        font-size: medium;
        color: $tooltip-main-text;

        .previous-price-indicator > div {
          margin-left: 2px;
          font-size: small;
        }
      }
    }

    .source-img {
      align-content: end;
      margin-bottom: 3px;
    }
  }

  .price-row {
    display: flex;
    width: 221px;
    padding-left: 4px;
    padding-right: 4px;
    transition: all 0.25s ease-in-out;
    border: 1px solid transparent;

    .fuel-grade-img {
      display: flex;
      flex-wrap: wrap;
      align-self: center;
      align-content: center;
      justify-content: center;
      font-size: medium;
      width: 40px;
      height: 30px;
      border-radius: 8px;
      color: $white;
      font-weight: 500;
      margin-right: 12px;
    }

    .price-info {
      margin: 3px;
      justify-content: left;
      align-content: center;
      font-weight: 500;
      width: 150px;

      .date {
        font-size: small;
        color: $light;
      }

      .selected-fg-date {
        font-size: small;
        color: $tooltip-main-text;
      }

      .price {
        display: flex;
        font-size: medium;
        color: $tooltip-main-text;

        .previous-price-indicator > div {
          margin-left: 2px;
          font-size: small;
        }
      }
    }

    .source-img {
      align-content: end;
      margin-bottom: 3px;
    }
  }

  .selected-fg-row {
    border-radius: 10px;
    margin-top: 3px;
    padding-left: 3px;
    padding-right: 3px;
  }
  .cheaper-fg {
    border: 1px solid $pink;
    background-color: rgba($pink, 0.15);
  }

  .match-fg {
    border: 1px solid $purple;
    background-color: rgba($purple, 0.2);
  }

  .expensive-fg {
    border: 1px solid $yellow;
    background-color: rgba($yellow, 0.15);
  }

  .default-fg {
    border: 1px solid $extra-dark;
    background-color: rgba($extra-dark, 0.1);
  }
}