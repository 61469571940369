@import '~styles/variables';
@import '~styles/mixins';

.confirm-changes {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  .all-price,
  .cash-price,
  .credit-price {
    &.all-price {
      width: 100%;
    }

    &.cash-price,
    &.credit-price {
      align-items: center;
      display: flex;
      width: 50%;
    }

    &.credit-price {
      padding-left: 20px;
    }

    .price {
      color: #263836;
      font-size: 1.6rem;
      height: 16px;
      line-height: 1;
    }

    .pending-price {
      color: $orange;
      font-size: 2.4rem;
      line-height: 1.2;
    }
  }

  .divider {
    @include competitor-pricing-price-divider;
  }
}
