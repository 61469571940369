@import '~styles/variables';

@mixin widget-default-styles {
  background-color: $primary-bg;
  border-radius: $widget-border-radius;
  box-shadow: $widget-box-shadow;
  display: flex;
  flex-direction: column;
  margin-bottom: $widget-margin-bottom;
  padding: 25px;
}

@mixin tooltip-default-styles {
  > div:first-child::before {
    border-bottom-color: $white;
    border-top-color: $white;
    box-shadow: 0 10px 30px 0 rgba($black, 0.12);
    z-index: 0;
  }

  > div:last-child {
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba($black, 0.12);
    color: $tooltip-main-text;
    display: flex;
    font-size: 1.6rem;
    line-height: 22px;
    max-width: 240px;
    padding: 1rem 1.5rem;
    text-align: left;
    z-index: 0;
  }

  &.tooltip-right > div:first-child::before {
    border-color: transparent;
    border-right-color: $white;
  }

  &.tooltip-left > div:first-child::before {
    border-color: transparent;
    border-left-color: $white;
  }
}

@mixin modal-default-styles {
  > div {
    width: 340px;
  }

  > div > div {
    border-radius: 10px;
    height: 377px;
    text-align: center;

    > div {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
}

@mixin popover-default-styles {
  z-index: 1 !important;
}

@mixin competitor-pricing-cell {
  border-radius: 10px;
  display: flex;
  height: 70px;
  padding: 0 15px;
  width: 190px;
}

@mixin competitor-pricing-price-divider {
  border: solid 1px #bdc1c4;
  height: 37px;
}

@mixin restricted {
  color: $light;
  cursor: not-allowed;
}
