@import '~styles/variables';

.search-results {
  background: $stations-search-background;
  border-right: $thin-grey-border;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  left: 0;
  position: absolute;
  top: 80px;
  transform: translateY(-100vh);
  transition: transform 0.4s linear;
  width: 100%;
  z-index: 20;

  @media screen and (max-width: $station-search-full-width) {
    width: $main-width;
  }

  &.visible {
    transform: none;
  }

  .filters {
    box-shadow: 0 10px 14px 0 rgba(115, 129, 142, 0.07);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2.5rem 3rem 2rem;
    z-index: 10;

    .filter {
      align-items: center;
      background-color: #e2e8ec;
      border-radius: 10px;
      color: #72818f;
      cursor: pointer;
      display: flex;
      font-size: 1.6rem;
      font-weight: bold;
      height: 35px;
      justify-content: center;
      line-height: 1.38;
      margin: 0px 2.5px 5px 2.5px;
      width: 35px;

      &-active,
      &:hover {
        background-color: #72818f;
        color: $primary-bg;
      }
    }
  }

  .stations {
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    -ms-overflow-style: none;
    overflow-y: scroll;
    padding: 3.5rem 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      background: transparent;
      width: 0;
    }

    .station {
      align-items: center;
      background-color: $primary-bg;
      border-radius: 10px;
      box-shadow: 0 5px 10px 0 rgba($black, 0.05);
      display: flex;
      margin: 0.5rem 4rem;
      min-height: 80px;
      padding: 0 2rem;

      &:hover {
        background-color: #e2e8ec;
      }

      .name {
        flex: 1;
        font-size: 2.4rem;
        line-height: 1;
        padding-left: 5%;
      }
    }
  }

  .message {
    box-shadow: 0 -7px 5px -1px rgba($black, 0.12);
    color: $light;
    font-size: 1.6rem;
    line-height: 1.38;
    padding: 2rem 0;
    text-align: center;
    z-index: 10;
  }
}
