@import '~styles/variables';

.fuel-grade-price {
  display: flex;
  flex: 1 0 140px;
  flex-direction: column;
  justify-content: center;
  width: 30px;

  > div:first-of-type {
    color: $dark;
    font-size: 2.4rem;
  }

  .buttons {
    align-items: center;
    display: flex;
    height: 1.6rem;

    > svg {
      cursor: pointer;
      margin-right: 4px;
    }
  }
}
