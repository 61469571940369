@import '~styles/mixins';

.delivery-container {
  @include widget-default-styles;
  flex-direction: column;
  padding: 40px 0 40px 40px;
}

.delivery-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2.8rem;
  margin-right: 4rem;
}

.delivery-title {
  color: $dark;
  font-size: 2.4rem;
}

.delivery-body {
  display: flex;
  flex-direction: row;
}

.slider-arrow-container {
  display: flex;
  flex-direction: row;
}

.slider-arrow {
  padding-left: 1rem;
}

.widget-container {
  margin-right: 1rem;
}

.delivery-spinner {
  @include widget-default-styles;
  height: 223px;
  justify-content: flex-start;
  padding: 40px 0 40px 40px;
}
