@import '~styles/variables';
@import '~styles/mixins';

.prices-cell {
  @include competitor-pricing-cell;

  align-items: center;
  background-color: $competitor-pricing-my-station;
  justify-content: center;
  position: relative;

  &.pending-request {
    background-color: #fff2ef;
    border: solid 1px $orange;
  }

  &.active-request {
    background-color: rgba(255, 102, 65, 0.1);
    border: 1px $orange solid;
  }
}
