@import '~styles/variables';

.placeholder-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;

  .placeholder {
    align-self: flex-start;
    background-color: $placeholder;
    border: 3px solid $placeholder;
    border-radius: 3px;
    width: 70%;
  }
}
