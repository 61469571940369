@import '~styles/variables';

.side-bar {
  background-color: $dark-bg;
  display: flex;
  flex: 0 0 $sidebar-width;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;

  .edge-logo {
    align-items: center;
    display: flex;
    flex: 0 0 $sidebar-width;
    justify-content: center;
  }
}
