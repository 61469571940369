@import '~styles/variables';

.station-row {
  display: flex;
  height: 164px;

  &.no-cc {
    height: 82px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px #c0c0c0 solid;
  }
}
