@import '~styles/variables';

.edit-station-strategy {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 240px;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  .cancel {
    cursor: pointer;
    margin-top: -30px;
    position: absolute;
    right: -21px;
  }

  .title {
    align-items: center;
    color: $tooltip-comparative-text;
    display: flex;
    font-weight: 500;
    height: 40px;
  }
}
