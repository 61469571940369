@import '~styles/variables';

.total-row {
  background-color: #f4f6f7;
  border-bottom: 1px $grey-light solid;
  border-left: 2px solid #f4f6f7;
  display: flex;
  height: 80px;

  &.edit-pricing .total-cell {
    margin-left: 0;
    margin-right: 30px;
  }

  .total-cell {
    align-items: center;
    color: $dark;
    display: flex;
    flex: 1.2;
    font-size: 1.6rem;
    line-height: 1;
    margin-left: 30px;
  }
}
