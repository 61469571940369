
@import '~styles/variables';

.export-button { 
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  padding: 20px 0;

  &.loading > button {
    cursor: default;
  }
  
  > button {
    background-color: $green;
    border: 0;
    border-radius: 10px;
    color: $white;
    height: 44px;
    outline: none;
    overflow: hidden;
    width: 220px;

    &:hover,
    &:focus {
      background-color: $green;
      color: $white;
      outline: none;
      text-decoration: none;
    }

    > div {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      > svg {
        height: 20px;
        margin-right: 15px;
        width: 20px;
      }
    }
  }
}
