@import '~styles/variables';

.container {
  align-items: center;
  background-image: url('../../../../images/background.jpg');
  background-position: bottom center;
  background-size: cover;
  color: $white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  padding: 30px 0;
}

.wrapper {
  border-radius: 10px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  width: 450px;
  background-color: $white;
  padding: 30px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin-bottom: 30px;
}

.warning-icon {
  margin-bottom: 50px;
}

.help-text {
  color: #b3bfc9;
  text-align: center;
  margin:0 20px 10px;
  font-size: 1.6rem;
}

.links-wrapper {
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
}

.link {
  color: #b3bfc9;
  text-align: center;
  margin-top: 10px;
  font-size: 1.6rem;

  &:hover {
    text-decoration: underline;
  }
}

.footer {
  background: $green;
  border-radius: 0 0 10px 10px;
  color: $white;
  font-weight: bold;
  margin-top: -10px;
  padding: 25px 15px 15px;
  position: relative;
  text-align: center;
  z-index: 0;
}

.copyright {
  bottom: 0;
  left: 0;
  position: fixed !important;
  right: 0;
}
