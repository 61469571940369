@import '~styles/variables';

.price {
  flex: 0 0 55px;
  justify-content: flex-end;
}

.with-pending-request {
  display: flex;
  flex-direction: column;
  justify-content: end;

  .current-price {
    color: #b3bfc9;
    font-size: 1.6rem;
    line-height: 0.4;
  }

  .pending-request {
    color: $orange;
    font-size: 2.4rem;
  }
}
