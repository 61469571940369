@import '~styles/mixins';

.selector-container {
  background-color: $light-bg;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 20px;
  width: 340px;

  &.selected {
    border-radius: 10px 10px 0;
    width: 381px;
  }
}

.external-corner {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 4;
  height: 15px;
  margin-bottom: -5px;
  margin-right: -17px;
  width: 20px;
}

.external-corner-container {
  height: 15px;
  width: 15px;
}

.svg {
  height: 100%;
  margin-bottom: -47px;
  vertical-align: unset;
  width: 100%;
}

.svg-rect {
  height: 100%;
  width: 100%;
  x: 0;
  y: 0;
}

.svg-circle {
  cx: 0;
  cy: 100%;
  fill: $white;
  r: 100%;
}

.grade-title {
  align-items: center;
  border-radius: 7px;
  color: $white;
  display: flex;
  font-size: 1.6rem;
  font-weight: 500;
  height: 30px;
  justify-content: center;
  letter-spacing: -0.1rem;
  margin-right: 20px;
  width: 34px;
}

.grade-total {
  align-items: center;
  color: $dark;
  display: flex;
  font-size: 24px;
  font-style: normal;
  height: 30px;
  width: 200px;
}

.grade-trend {
  align-items: center;
  display: flex;
  font-size: 16px;
}
