@import '~styles/variables';
@import '~styles/mixins';

.status-selector {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 90px;
  z-index: 15;

  &.cancel {
    top: 40px;
  }

  @media screen and (max-width: $main-content-full-width) {
    right: -10px;
  }
}
