@import '~styles/variables';
@import '~styles/mixins';

.price-cell {
  @include competitor-pricing-cell;

  background-color: $competitor-pricing-my-station;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &.no-cash-credit {
    border: solid 1px $pricing-no-cash-credit;
  }

  &.pending-request {
    background-color: #fff2ef;
    border: solid 1px $orange;
  }

  &.active-request {
    background-color: rgba(255, 102, 65, 0.1);
    border: 1px $orange solid;
  }
}
