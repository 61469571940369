@import '~styles/variables';
@import '~styles/mixins';

.pole-price-popover {
  @include popover-default-styles;

  min-height: 208px;
  width: 270px;

  &.edit-price {
    min-height: 290px;
  }

  .content {
    display: flex;
    flex-direction: column;
    min-height: 243px;
    padding: 10px 15px;

    .title {
      align-items: center;
      display: flex;
      font-size: 1.6rem;
      font-weight: 500;
      height: 35px;
      margin-bottom: 10px;
    }
  }
}
