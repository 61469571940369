@import '~styles/variables';
@import '~styles/mixins';

.payment-container {
  align-items: center;
  background-color: $light-bg;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 70px;
  margin-bottom: 10px;
  max-width: 600px;
  min-width: 240px;
  padding: 14px 20px;
}

.payment-logo {
  margin-right: 20px;
}

.payment-value {
  color: $dark;
  font-size: 24px;
  line-height: 1.3;
}

.payment-tooltip {
  @include tooltip-default-styles;

  &.capitalise {
    text-transform: capitalize;
  }
  > div:last-child {
    max-width: 200px;
  }
}
