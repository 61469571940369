@import '~styles/mixins';

.station-performance-container {
  @include widget-default-styles;
  padding: 40px;
}

// TODO external curve on active tab
/*
.nav-metrics-active::before {
  content:"";
  position: absolute;
  //bottom:0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #BE3569;
  z-index: 6;
  margin-left: -40px;
  margin-top: 50px;
}
.nav-metrics-active::after {
  content:"";
  position:relative;
  //bottom:0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #BE3569;
  margin-right: -20px;
  margin-top: 50px;
  z-index: 0;
}
*/

.station-performance-spinner {
  @include widget-default-styles;
  height: 606px;
  justify-content: flex-start;
  padding: 40px 0 40px 40px;
}
