@import '~styles/variables';

.group-row {
  background-color: #f4f6f7;
  border-bottom: 1px $grey-light solid;
  display: flex;
  height: 81px;
}

.loading-row {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 81px;
  justify-content: center;
}
