@import '~styles/variables';
@import '~styles/mixins';

.station-status-item {
  display: flex;

  .status-indicator-wrapper {
    align-items: center;
    display: flex;
    margin-right: 10px;

    .status-indicator {
      background-color: $grey;
      border-radius: 50%;
      min-height: 10px;
      min-width: 10px;

      &.black {
        background-color: $station-status-black;
      }

      &.green {
        background-color: $station-status-green;
      }

      &.amber {
        background-color: $station-status-amber;
      }

      &.red {
        background-color: $station-status-red;
      }
    }
  }
}

.status-tooltip {
  @include tooltip-default-styles;

  .content {
    color: $light;
    display: flex;
    flex-direction: column;
    min-width: 220px;

    h3 {
      color: #383d41;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 10px 0;
    }
  }

  > div:last-child {
    max-width: 270px;
  }
}
