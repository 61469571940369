@import '~styles/variables';
@import '~styles/mixins';

.container {
  @include widget-default-styles;

  flex-direction: column;
  min-height: 400px;
  padding: 40px 0 40px 40px;

  h2 {
    color: $dark;
    font-size: 2.4rem;
  }

  > div {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 60px 0 30px;

    > h3 {
      color: $dark;
      font-size: 2.4rem;
      margin-top: 20px;
    }

    > p {
      color: $light;
      margin-top: 15px;
      max-width: 282px;
      text-align: center;
    }

    > button {
      background-color: $green;
      border-radius: 10px;
      border-style: none;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      color: $white;
      font-weight: bold;
      height: 54px;
      margin-bottom: 30px;

      margin-top: 30px;
      width: 140px;

      &:hover {
        background-color: $green;
        color: $white;
      }
    }
  }
}
