@import '~styles/variables';

.edit-changes-button {
  align-items: center;
  background-color: $tooltip-main-text;
  border-radius: 10px;
  color: $white;
  cursor: pointer;
  display: flex;
  flex: 190px 0 0;
  font-weight: 500;
  height: 57px;
  justify-content: center;
  margin-right: 10px;

  span {
    padding-left: 10px;
  }
}
