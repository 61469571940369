@import '~styles/variables';

.action-at {
  margin-right: 80px;
  width: 270px;

  .title {
    align-items: center;
    display: flex;
    margin-bottom: 15px;

    svg {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .selected-action-at {
    align-items: center;
    background-color: $light-bg;
    border-radius: 10px;
    color: $tooltip-main-text;
    cursor: pointer;
    display: flex;
    height: 57px;
    margin-top: 10px;
    padding: 20px;
    width: 100%;
  }
}
