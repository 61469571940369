@import '~styles/variables';
@import '~styles/mixins';

.fuel-grade-differential-popover {
  @include popover-default-styles;

  height: 264px;
  padding: 20px 15px;
  width: 270px;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;

    h3 {
      border-bottom: $thin-grey-border;
      font-size: 16px;
      padding-bottom: 15px;
    }

    .value {
      align-items: center;
      color: $dark;
      display: flex;
      flex: 1;
      font-size: 4rem;
      justify-content: center;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .minus,
      .plus {
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        min-height: 44px;
        min-width: 48%;
      }

      .minus {
        border: solid 1px #718190;
      }

      .plus {
        border: solid 1px $green;
      }
    }

    .btn {
      background-color: $green;
      border: 0;
      border-radius: 10px;
      color: $white;
      font-size: 1.6rem;
      font-weight: bold;
      height: 44px;
      outline: none;
      width: 100%;

      &.disabled {
        background-color: $dark-grey;
        color: $dark;
        cursor: not-allowed;
        opacity: 0.65;
      }
    }
  }
}
