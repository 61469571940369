.new-price {
  display: flex;
  flex-direction: column;
  justify-content: end;

  .current-price {
    color: #b3bfc9;
    font-size: 1.6rem;
    line-height: 0.4;
  }
}
