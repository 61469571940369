.all-stations-checkbox {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 80px;

  svg {
    cursor: pointer;
  }
}
