@import '~styles/variables';
@import '~styles/mixins';

.edit-pricing-button {
  align-items: center;
  background-color: $dark;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: space-evenly;
  line-height: 1.8;
  width: 122px;

  &.inactive {
    background-color: $inactive;
    cursor: not-allowed;
  }
}
