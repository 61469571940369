.metric-cell {
  p {
    margin: 0;

    &:nth-of-type(2) {
      color: #b3bfc9;
      padding-top: 5px;
    }
  }
}
