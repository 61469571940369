.station-navigation {
  display: flex;
  margin-top: 5px;

  .divider {
    align-self: stretch;
    border: solid 1px #bdc1c4;
    margin: 2px 10px;
  }
}
