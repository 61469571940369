@import '~styles/variables';
@import '~styles/mixins';

.modal {
  @include modal-default-styles;

  > div > div > div {
    .icon {
      margin-top: 40px;
    }

    .body {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-top: 40px;
      width: 70%;

      h4 {
        color: $dark;
        font-size: 2.4rem;
        margin-bottom: 15px;
      }

      p {
        color: $light;
      }
    }

    .button {
      background-color: $green;
      color: $white;
      height: 54px;
      margin-bottom: 20px;
      width: 280px;
    }
  }
}
