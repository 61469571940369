@import '~styles/variables';

.no-data {
  align-items: center;
  background-color: $white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: $widget-box-shadow;
  display: flex;
  flex: 1;
  justify-content: center;

  .content {
    text-align: center;

    .emoji {
      align-items: center;
      background-color: $dark;
      border-radius: 50%;
      display: flex;
      height: 42px;
      justify-content: center;
      margin: auto;
      width: 42px;
    }

    h2 {
      color: $dark;
      font-size: 2.4rem;
      padding: 30px 0 20px;
    }

    p {
      color: $light;
      text-align: center;
      width: 250px;
    }
  }
}
