@import '~styles/mixins';

.fuel-grade-selector-container {
  display: flex;
  flex-direction: row;
}

.fuel-grade-selector-widget-container {
  display: flex;
  flex-direction: column;
  min-width: 380px;
  z-index: 5;
}

.fuel-grade-metrics-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
}

.fancy-fuel-grade-selector-curve {
  border-bottom: 2.5px solid $grey-light;
  border-left: 2.5px solid $grey-light;
  border-radius: 0 0 0 20px;
  display: flex;
  margin-left: -2px;
  width: 100%;
  z-index: 0;

  .fancy-curve-mask {
    background-color: $white;
    height: 85px;
    margin-left: -3px;
    width: 6px;
  }
}

.fuel-grade-metrics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.data-container {
  margin-bottom: 40px;
  margin-top: 40px;
  min-width: 300px;
}

.grades-title {
  color: $dark;
  font-size: 24px;
}

.metrics-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 40px 40px;
  width: 100%;
}

.metrics-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 40px 40px;
  width: 100%;
}

.vertical-spacer {
  display: flex;
  padding: 80px 0 50px;
}

.line {
  border-right: 1px solid $grey-light;
  height: auto;
}
