@import '~styles/variables';
@import '~styles/mixins';

.error-tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 300px;
    text-align: center;
  }
}
