.view-map {
  height: 57px;
  text-align: center;
  padding: 18px 20px 20px;
  width: 100%;
  font-weight: bold;
}

.modal-overlay-style {
  margin-left: 0 !important;
  overflow: hidden !important;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
  
.modal-content-style {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}
  
.close-button-style {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.title-divider {
  width: 100%;
  margin: 10px auto;
  border: 0;
  height: 1px;
  background-color: #ccc;
}

.regions-map {
  width: 100%;
  height: auto;
}

.modal-title {
  color: #73818e;
}
