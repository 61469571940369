@import '~styles/mixins';

.delivery-date {
  color: #b3bfc9
}

.tooltip {
  @include tooltip-default-styles;

  > div:last-child {
    max-width: 350px;
  }
}

.info-rows {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.info-item {
  flex-direction: column;
}

.info-item.left {
  text-align: left;
  align-items: flex-start;
  padding-right: 20px;
}

.info-item.right {
  text-align: right;
  align-items: flex-end;
  padding-left: 20px;
}

.title {
  color: #939393;
  font-size: 14px;
}

.value {
  font-size: 16px;
  color: #656565;
  font-weight: 500;
}
