@import '~styles/variables';

.options {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 10px;

  .delete {
    color: $pink-med;
    cursor: pointer;
    font-weight: 500;
    line-height: 1.38;
    margin-right: 20px;
    text-decoration: underline;
  }

  .delete-spinner {
    margin-right: 20px;
  }

  .save {
    background-color: $green;
    color: $white;
    height: 44px;
    width: 110px;

    &:hover {
      background-color: $green;
      color: $white;
    }

    &.invalid {
      cursor: not-allowed;
      opacity: 0.65;
    }
  }
}
