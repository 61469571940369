@import '~styles/variables';
@import '~styles/mixins';

.filter-popover {
  @include popover-default-styles;

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    min-height: 199px;
    padding: 20px;
    width: 270px;

    .title {
      color: $tooltip-comparative-text;
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
}
