@import '~styles/variables';

.dropdown {
  align-items: center;
  align-self: center;
  background-color: $light-bg;
  border: 1px solid $light-bg;
  border-radius: 10px;
  color: $dark;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: 57px;
  justify-content: space-between;
  margin-top: 10px;
  min-height: 57px;
  padding: 20px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-icon {
    margin-left: 20px;
  }

  &.open {
    border: 0;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .placeholder {
    color: $light;
  }

  .items {
    background-color: $white;
    border: 1px solid $green;
    border-radius: 10px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    overflow: auto;

    .item {
      align-items: center;
      border-bottom: 1px solid $grey-uulight;
      display: flex;
      height: 57px;
      justify-content: space-between;
      padding: 20px;

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .items-list {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  .selection-helpers {
    align-items: stretch;
    background-color: $grey-uuulight;
    border-top: 1px solid $grey-uulight;
    display: flex;
    flex-flow: row nowrap;
    height: 57px;
    justify-content: flex-start;
    margin-top: -1px;

    > * {
      align-items: center;
      background: none;
      border-style: none;
      color: $tooltip-main-text;
      display: flex;
      flex: 1 1 50%;
      flex-flow: row nowrap;
      font: inherit;
      justify-content: center;
    }

    > * + * {
      border-left: 1px solid $grey-uulight;
    }
  }

  .search {
    align-items: stretch;
    border-bottom: 1px solid $grey-uulight;
    display: flex;
    flex-flow: row nowrap;
    height: 57px;
    justify-content: flex-start;

    input {
      border-style: none;

      color: inherit;
      font: inherit;
      outline: none;
      padding: 18px 20px 20px;
      width: 100%;

      &::placeholder {
        color: inherit;
        font: inherit;
      }
    }

    svg {
      align-self: center;
      margin-right: 20px;
      min-width: 20px;
    }
  }
}
