@import '~styles/variables';

.cash-credit-differential {
  align-items: center;
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: $dark;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 50%;
  line-height: 1;
  min-width: 45px;
  position: absolute;
  top: -8px;
  transform: translateX(-50%);
}
