@import '~styles/variables';

.station-title-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 19px;
  margin-top: -10px;

  .station-title-brand {
    margin-left: -8px;
    margin-right: 10px;
  }

  .station-name {
    color: $station-title;
    font-size: 24px;
    line-height: 1;
  }
}
