@import '~styles/variables';
@import '~styles/mixins';

.pricing-type-selector {
  align-items: center;
  border-bottom: $thin-grey-border;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 30px;

  .cash-credit-toggles {
    display: flex;

    > div:not(:last-of-type) {
      margin-right: 20px;
    }
  }
}

.cash-credit-tooltip {
  @include tooltip-default-styles;

  top: 10px !important;
}
