@import '~styles/bootstrap';
@import '~bootstrap/scss/bootstrap';
@import '~styles/fonts';
@import '~styles/variables';

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {
  --toastify-toast-min-height: 90px;
  --toastify-toast-width: 380px;
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: CircularXXSub-BookSubset, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.tooltip {
  max-width: none;

  &.show {
    opacity: 1;
  }
}

.btn {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  color: $green;
  font-size: 1.6rem;
  font-weight: bold;

  &:hover {
    background-color: $white;
    color: $green;
  }
}

.popover {
  border: 0;
  border-radius: 10px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  left: none;

  .arrow::before {
    border: 0;
  }
}

// toastify
// stylelint-disable selector-class-pattern
.Toastify__toast-theme--light.Toastify__toast--error {
  border: 1px solid $pink-med;
  border-radius: 10px;
  color: $tooltip-main-text;

  .Toastify__toast-body {
    white-space: pre-line;

    > div:first-of-type {
      justify-content: center;
      width: 40px;
    }
  }
}
// stylelint-enable selector-class-pattern

// leaflet
.leaflet-bar a, .leaflet-bar a:hover {
  text-decoration: none !important;
}
